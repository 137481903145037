<div class="container w-full px-4 md:px-8 sm:pt-4 ml-auto mr-auto pb-12 pt-0" style="max-width:90%">
    <section class="breadcrumb flex mt-2" *ngIf="!isCategoryDetails">
        <ul class="flex">
            <li><a href="./home.component.html">Home</a></li>
            <span class="ml-2 mr-2">/</span>
            <li class="font-bold">{{isMyLibrary ? 'My Library' : isGlobalSearch ? 'Global Search' : 'Search'}}</li>
        </ul>
    </section>
    <!-- Bread crumb -->
    <div class="breadcrumb flex mt-2" *ngIf="isCategoryDetails">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black">{{(selectedCategory?.printable)? 'Print' :
            'Digital'}} Products</a>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" [routerLink]="['/','category', selectedCategory?.slug]">{{selectedCategory?.title}}</a>
        <span class="ml-2 mr-2">/</span>
        <p style="font-weight:600">{{ selectedSubCategory?.title}}</p>
    </div>
    <div class="mt-10 mb-12">
        <h1 style="font-weight:700;font-size:23px;" class="text-center sm:text-start">
            {{productDetails?.title}}</h1>
        <p class="mt-2 mb-2 text-black text-center sm:text-start" style="font-weight:400;font-size:14px;">
            {{productDetails?.description}}
        </p>
    </div>

    <form class="flex flex-row sm:hidden gap-2 w-full mt-6">
        <!-- <label for="default-search"
                class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label> -->
        <mat-form-field class="grow" subscriptSizing="dynamic"   *ngIf="!isCategoryDetails">
            <mat-icon style="color: #afafaf !important;" svgIcon="heroicons_outline:magnifying-glass"
                matPrefix></mat-icon>
            <input matInput id="default-search" [formControl]='searchFormControl'
                class="relative block w-full text-sm text-black border-none" placeholder="Search Mockups, Logos...">
        </mat-form-field>
        <button type="submit" class="btn_normal" (click)="openFilterModal()">Filter</button>
    </form>

    <div class="flex flex-col sm:flex-row mt-6 gap-8">
        <form class="w-full sm:w-64 shrink-0 hidden sm:flex flex-col" >
            <mat-form-field class="w-full" subscriptSizing="dynamic"  *ngIf="!isCategoryDetails">
                <mat-icon style="color: #afafaf !important;" svgIcon="heroicons_outline:magnifying-glass"
                    matPrefix></mat-icon>
                <input matInput id="default-search" [formControl]='searchFormControl'
                    class="relative block w-full  text-sm text-black  border-none"
                    placeholder="Search Mockups, Logos...">
            </mat-form-field>
            <pwa-search-filter class="hidden sm:flex" [ngClass]="isCategoryDetails ? ' -mt-5' : ''" [filter]="filterParams" [configurations]="searchConfig"
                (onFilterChange)="onFilterChange($event)"></pwa-search-filter>
        </form>
        <div class="flex flex-col grow md:ml-6">
            <div class="flex flex-col md:flex-row gap-4 mb-6 items-center" *ngIf="isMyLibrary">
                <div class="flex gap-4 items-center grow w-full justify-between">
                    <label style="text-wrap: nowrap;">Tags</label>
                    <form class="w-full shrink">
                        <mat-form-field class="w-full md:w-[50%]" subscriptSizing="dynamic">
                            <mat-select placeholder="Tags" [(ngModel)]="filterParams.user_tag"
                                [ngModelOptions]="{standalone: true}" (selectionChange)="updateUrlForSearch()">
                                <mat-option [value]="null">-All-</mat-option>
                                <mat-option *ngFor="let tag of userTags" [value]="tag.id">{{tag.tag}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </form>
                </div>
                <button type="submit" class="btn_normal shrink-0 w-full md:w-auto" (click)="openManageTags()">Manage
                    Tags</button>
            </div>
            <ng-container *ngIf="products?.length > 0 ; else noData">
                <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 mb-4">
                    <div *ngFor="let product of products; let i = index" class="h-fit">
                        <ng-container *ngIf="isMyLibrary">
                            <app-design-view [product]="product" class="cursor-pointer" [showMenu]="true"
                                (onDelete)="onClickDelete(product)" (onRemoveTag)="onClickRemoveTag(product)"
                                (onAssignTag)="openManageTags(product)" (onUpdateTag)="openManageTags(product)"
                                (click)="getVersionHistory(product, true)"></app-design-view>
                        </ng-container>

                        <ng-container *ngIf="!isMyLibrary && !isGlobalSearch">
                            <a [routerLink]="['/', 'product',product.id]">
                                <app-design-view [product]="product" class="cursor-pointer"
                                    [showMenu]="false"></app-design-view>
                            </a>
                        </ng-container>

                        <ng-container *ngIf="!isMyLibrary && isGlobalSearch && !isCategoryDetails">
                            <a [routerLink]="['/', 'product-version',product.id]">
                                <app-design-view [product]="product" class="cursor-pointer"
                                    [showMenu]="false"></app-design-view>
                            </a>
                        </ng-container>

                        <ng-container *ngIf="isCategoryDetails">
                            <a (click)="getVersionHistory(product, false)">
                                <app-design-view [product]="product" class="cursor-pointer"
                                    [showMenu]="false"></app-design-view>
                            </a>
                        </ng-container>

                    </div>
                    <pwa-pagination class="col-span-2 md:col-span-3 lg:col-span-4 justify-self-center mt-8 mb-8"
                        (onPageChange)="onPageChange($event)" [pagination]="pagination"></pwa-pagination>
                </div>
                <!-- TW Elements is free under AGPL, with commercial license required for specific uses. See more details: https://tw-elements.com/license/ and contact us for queries at tailwind@mdbootstrap.com -->

            </ng-container>

        </div>

    </div>

    <!-- /container -->
</div>


<ng-template #noData>
    <div class="flex flex-col items-center w-full p-10 md:p-20">
        <p class="text-black mb-1 text-center" style="font-size:18px;font-weight:500;font-family:poppins">
            There are no products matching your query
        </p>
        <p class="text-black text-center" style="font-size:13px;font-weight:normal;font-family:poppins">
            Sorry, it seems there are no products matching your search.
            Please try again with a different keyword or browse our catalog for available products.
        </p>
    </div>

</ng-template>