import { FuseNavigationItem } from "@fuse/components/navigation";
import { LocalStorageService } from "app/core/localstorage/local-storage.service";
import { ReplaySubject } from "rxjs";

export class PWANavigation {

    constructor(private _localStorage: LocalStorageService) {

    }

    private _navigation: ReplaySubject<FuseNavigationItem[]> = new ReplaySubject<FuseNavigationItem[]>(1);
    navigation: FuseNavigationItem[] = [

        // {
        //     id: 'home',
        //     title: 'Home',
        //     type: 'basic',
        //     link: '/home'
        // },
        {
            id: 'print-products',
            title: 'Print Products',
            type: 'collapsable',
            children: []
        },
        {
            id: 'digital-products',
            title: 'Digital Products',
            type: 'collapsable',
            children: []
        },
        {
            id: 'social-media',
            title: 'Social Media',
            type: 'basic',
            link: '/social-media/posts',
            disabled: true,
            hidden: (item: FuseNavigationItem) => item.disabled == true
        },
        {
            id: 'pricing',
            title: 'Pricing',
            type: 'basic',
            link: '/pricing'
        },
        {
            id: 'help',
            title: 'Help',
            type: 'collapsable',
            children: [
                {
                    id: 'help.contact',
                    title: 'Contact Us',
                    type: 'basic',
                    link: '/contact-us'
                },
                {
                    id: 'help.faq',
                    title: 'FAQ',
                    type: 'basic',
                    link: '/help/faq'
                },
            ]
        },
        {
            id: 'blog',
            title: 'Blog',
            type: 'basic',
            externalLink: true,
            link: 'https://blog.printingwarehouses.com'
        },
    ];
}