<mat-dialog-content class="p-0" style="height: 100vh;">
    <div class="flex justify-end items-end  mb-2 sm:mb-0 p-0"
        style="min-height:fit-content !important">
        <button class="flex mb-0 justify-end" mat-button (click)="close()">
            <img alt="Close icon" src="./assets/images/close_icon.svg"
                style="width:17px;height:17px;object-fit: contain;">
        </button>

    </div>
    <div class="flex flex-col items-center md:items-start md:flex-row gap-8 md:gap-12 mb-12">
        <mat-tab-group *ngIf="selectedHistory" #tabGroup style="height:100%" class="mt-0 md:w-80">

            <mat-tab *ngFor="let design of selectedHistory?.designs" [label]="design?.title">
                <div class="h-[40vh] bg-[#f5f5f5] md:-m-[24px]" style="padding:24px;margin-top:8px !important;border-radius:10px;">
                    <img [src]="design.thumb" onerror="this.src='./assets/images/placeholder.png'"
                        style="width:100%;height:100%;object-fit: contain;" alt="Design preview">
                </div>
            </mat-tab>

        </mat-tab-group>

        <div class="p-0 sm:pl-[20px] sm:pr-[20px] sm:pb-[20px] max-w-100" style="padding:0px !important;">
            <h1 class="text-black" style="font-weight:700;font-size:23px;">Configurations</h1>
            <p class="text-black mt-2" style="font-size:14px;">
                Here, you can choose and configure various settings and options for your products
            </p>
            <form class="mt-6 mb-4 max-w-100" style="border-radius:10px;">
                <mat-form-field class="w-full" *ngFor="let config of configurations; let i = index" floatLabel="always">
                    <mat-label class="text-black">{{config.title}}</mat-label>
                    <mat-select [(ngModel)]="config.selected" (selectionChange)='onOptionsSelected($event.value, i)'
                        [ngModelOptions]="{standalone: true}" class="text-gray-400  w-full"
                        placeholder="Please select an option">
                        <mat-option *ngFor="let option of config.options;let i=index;"
                            [value]="option.id">{{option.title}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-full" *ngIf="quantityList?.length > 0" floatLabel="always">
                    <mat-label class="text-black">Quantity</mat-label>
                    <mat-select [(ngModel)]="selectedQuantity" (selectionChange)='onSelectQuantity($event.value)'
                        [ngModelOptions]="{standalone: true}" class="text-gray-400  w-full"
                        placeholder="Please select an option">
                        <mat-option *ngFor="let quantity of quantityList; let i = index"
                            [value]="quantity">{{quantity.quantity +' ($'+quantity.price+')'}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="w-full flex items-center justify-center">
                    <mat-progress-spinner *ngIf="isGettingPrice" [diameter]="24" [mode]="'indeterminate'">
                    </mat-progress-spinner>
                </div>
            </form>
            <p class="text-black flex justify-start items-center" style="font-size:18px;">Price <span class="ml-2"
                    style="font-size:20px;font-weight:600;">${{selectedQuantity ? selectedQuantity?.price : '-'}}</span>
            </p>
            <button mat-flat-button
                class="flex justify-center sm:ml-auto  items-center mt-4 w-full sm:w-[220px] text-center cursor-pointer btn_normal"
                (click)="addToCart()">
                {{data?.isForDesignSelection ? 'Choose Configuration' : 'Place Your Order'}}
            </button>
            <p class="text-center sm:text-end mt-4 m-auto flex justify-center sm:justify-end text-black"
                style="font-size:12px;font-weight:200;line-height: 1.2;">
                * Pricing does not include Taxes, Shipping and Handling</p>

        </div>
    </div>

</mat-dialog-content>