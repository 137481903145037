import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { HomeBanner } from '../home/home.types';
import { HomeService } from '../home/home.service';
import { MetaService } from 'app/core/meta/meta.services';


@Component({
  selector: 'about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, MatInputModule, MatFormFieldModule, MatButtonModule]
})

export class AboutUsComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  @ViewChild('ebookNgForm') ebookNgForm: NgForm;
  ebookForm: UntypedFormGroup;
  banners: HomeBanner[];

  date = new Date()
  ngOnInit(): void {
    this._homeService.banners$
    this.ebookForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
  configureBanners() {
    throw new Error('Method not implemented.');
  }
  // /download button
  downloadEbook() {
    this.ebookForm.markAllAsTouched();
    if (this.ebookForm.invalid) {
      return;
    }
    this._spinner.show()
    let request = this._homeService.downloadEBook(this.ebookForm.value)
    request.subscribe({
      next: (response: any) => {
        this._spinner.hide()
        this.ebookForm.enable()
        this._fuseConfirmation.open({
          title: "Success",
          message: response.message ? response.message : "Thank you for subscribing to our newsletter",
          dismissible: false,
          actions: {
            cancel: { show: false },
            confirm: { label: "Ok" }
          },
          icon: { show: false }
        });
      },
      error: error => {
        this._spinner.hide()
        this.ebookForm.enable()
        this._toast.error(error ? error.error.message : "Something went wrong. Please try again")
      }
    })
  }
  aboutData = [
    {
      icon: './assets/images/innovative_design_icon.svg',
      title: "Innovative Designs",
      content: `Explore our easy-to-use online tools designed to 
simplify your design and printing needs. We’re 
continuously enhancing our platform to ensure a 
smooth, hassle-free experience every step of the 
way. Experience the ease and efficiency of our 
advanced features.`
    },
    {
      icon: './assets/images/unified_brand_icon.svg',
      title: "Unified Brand Experience",
      content: `Enjoy the ease of having a consistent design for all your marketing materials. Our templates and tools help keep your brand message clear and on point, whether you’re making custom business cards, brochures, or creative sign designs. Make your brand stand out and get noticed.`
    },
    {
      icon: './assets/images/guaranteed_quality_icon.svg',
      title: "Guaranteed Quality",
      content: `Your satisfaction is our priority. We guarantee the quality of every product we sell. If it doesn’t meet your expectations, we’ll go above and beyond to make it right, ensuring you’re completely satisfied with your purchase. We are committed to delivering exceptional service and support every step of the way.`
    }

  ];
  hideRequiredMarker = "true"
  /**
   * Constructor
   */
  constructor(private _homeService: HomeService, private _spinner: NgxSpinnerService, private _confirmationService: FuseConfirmationService,
    private _toast: ToastrService, private _fuseConfirmation: FuseConfirmationService, private fb: UntypedFormBuilder, private metaService: MetaService) {
    // metaService.updateTitle()
  }
}