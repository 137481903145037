<section class="flex-auto p-0 px-[15px] md:px-7 pb-6 pt-6 m-auto" style="max-width:90%;">

    <!-- Banner -->
    <div class="flex flex-col md:flex-row items-center gap-8 md:gap-10 lg:gap-30  pl-[30px] pr-[30px] md:pt-[0px] md:pr-[50px] pb-0 md:pl-[50px]"
        style="border-radius:20px;box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.16);border: solid 1px #dedeff;">

        <div class="flex flex-col pb-8 pt-8 grow-1">
            <h1 class="text-center md:text-start m-auto md:ml-0 text-[#060266]"
                style="font-weight:700;font-size:23px;max-width:570px">Payment Request for Your Contribution</h1>
            <p class="mt-2 mb-2 text-black text-center md:text-start" style="font-weight:400;font-size:14px;">
                This payment link has been created for a contribution towards an order placed by
                {{cartDetails?.placed_by}}. Please review the details below carefully. If you have any questions or
                concerns about this payment, please reach out to {{cartDetails?.placed_by}} using the contact details
                provided.</p>
            <div
                class="flex flex-col sm:flex-row gap-8 md:gap-12 sm:items-center mt-8 flex-wrap content-center md:content-start w-full">
                <a class="flex flex-row items-center gap-3 shrink-0">
                    <div class="p-2 rounded-full w-auto"
                        style="border: solid 1px #ddd;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);">
                        <img src="./assets/images/user_info_icon.svg" class="w-4 h-4">
                    </div>
                    <div style="line-height: 1.3;">
                        <p style="font-size:13px;">Initiated By</p>
                        <p style="font-weight:bold">{{cartDetails?.placed_by}}</p>
                    </div>

                </a>
                <a class="flex flex-row items-center gap-3 shrink-0" href="tel:800-282-3453">
                    <div class="p-2 rounded-full w-auto"
                        style="border: solid 1px #ddd;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);">
                        <img src="./assets/images/icon_call.svg" class="w-4 h-4">
                    </div>
                    <div style="line-height: 1.3;">
                        <p style="font-size: 13px;">Contact Number</p>
                        <p style="font-weight:bold">{{cartDetails?.phone_number}}</p>
                    </div>

                </a>
                <a class="flex flex-row items-center gap-3 shrink-0">
                    <div class="p-2 rounded-full w-auto"
                        style="border: solid 1px #ddd;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);">
                        <img src="./assets/images/icon_order.svg" class="w-4 h-4">
                    </div>
                    <div style="line-height: 1.3;">
                        <p style="font-size: 13px;">Order Reference</p>
                        <p style="font-weight:bold">{{cartDetails?.reference_id}}</p>
                    </div>

                </a>
            </div>
        </div>
        <div class="self-end hidden md:flex shrink-0">
            <img src="./assets/images/pay_with_link_banner.svg" alt="Payment requests" style="max-height: 180px;">
        </div>
    </div>
    <!-- /Banner -->

    <div class="flex flex-col md:flex-row gap-10 mt-12">
        <div class="flex flex-row relative w-full">
            <div class="relative w-full">
                <div class="flex flex-wrap gap-6 justify-between w-full">
                    <div class="grow">
                        <ng-container>
                            <p class="text-black mb-3" style="font-weight: bold;font-size:15px;">
                                Shipping Address
                            </p>
                            <p class="text-black" style="font-weight: 400;font-size:14px;">
                                <span> {{cartDetails?.shipping_address}},</span>
                            </p>
                        </ng-container>
                        <div class="divider-y"></div>

                        <ng-container>
                            <p class="text-black mb-3" style="font-weight: bold;font-size:15px;">
                                Billing Details
                            </p>

                            <mat-checkbox class="mt-0 -ml-3" [(ngModel)]="useShippingAddressAsShippingAddress"
                                (change)="useShippingAsBilling($event)">
                                Use the shipping address as my billing address.
                            </mat-checkbox>

                            <form [formGroup]="createCardForm" #createCardNgForm="ngForm"
                                class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2">
                                <mat-form-field class="col-span-1 md:col-span-2 xl:col-span-3 mt-2">
                                    <mat-label>Search Address</mat-label>
                                    <input matInput matGoogleMapsAutocomplete [country]="'us'"
                                        (onAutocompleteSelected)="onAutocompleteSelected($event)"
                                        (onLocationSelected)="onLocationSelected($event)">
                                    <mat-icon [svgIcon]="'nav_search'" matPrefix class="text-[#afafaf] m-0" style="min-height:18px;height:18px;">search</mat-icon>
                                </mat-form-field>

                                <!-- Name field -->
                                <mat-form-field class="w-full">
                                    <mat-label>Name</mat-label>
                                    <input id="name" matInput type="text" [formControlName]="'name'" class="text-black">
                                    <mat-error *ngIf="createCardForm.get('name').hasError('required')">
                                        This field shouldn't be empty
                                    </mat-error>
                                </mat-form-field>
                                <!-- Address line 1 field -->
                                <mat-form-field class="w-full">
                                    <mat-label>Address Line 1</mat-label>
                                    <input id="address_line_1" matInput type="text" [formControlName]="'address_line_1'"
                                        class="text-black">
                                    <mat-error *ngIf="createCardForm.get('address_line_1').hasError('required')">
                                        This field shouldn't be empty
                                    </mat-error>
                                </mat-form-field>
                                <!-- Address line 2 field -->
                                <mat-form-field class="w-full">
                                    <mat-label>Address Line 2</mat-label>
                                    <input id="address_line_2" matInput type="text" [formControlName]="'address_line_2'"
                                        class="text-black">
                                    <mat-error *ngIf="createCardForm.get('address_line_2').hasError('required')">
                                        This field shouldn't be empty
                                    </mat-error>
                                </mat-form-field>
                                <!-- City field -->
                                <mat-form-field class="w-full">
                                    <mat-label>City</mat-label>
                                    <input id="city" matInput type="text" [formControlName]="'city'" class="text-black">
                                    <mat-error *ngIf="createCardForm.get('city').hasError('required')">
                                        This field shouldn't be empty
                                    </mat-error>
                                </mat-form-field>
                                <!-- State field -->
                                <mat-form-field class="w-full">
                                    <mat-label>State</mat-label>
                                    <input id="state" matInput type="text" [formControlName]="'state'"
                                        class="text-black">
                                    <mat-error *ngIf="createCardForm.get('state').hasError('required')">
                                        This field shouldn't be empty
                                    </mat-error>
                                </mat-form-field>
                                <!-- zipcode field -->
                                <mat-form-field class="w-full">
                                    <mat-label>ZIP Code</mat-label>
                                    <input id="zipcode" matInput type="text" [formControlName]="'zipcode'"
                                        class="text-black">
                                    <mat-error *ngIf="createCardForm.get('zipcode').hasError('required')">
                                        This field shouldn't be empty
                                    </mat-error>
                                </mat-form-field>
                                <!-- Email field -->
                                <mat-form-field class="w-full">
                                    <mat-label>Email</mat-label>
                                    <input id="email" matInput type="text" [formControlName]="'email'"
                                        class="text-black">
                                    <mat-error *ngIf="createCardForm.get('email').hasError('required')">
                                        This field shouldn't be empty
                                    </mat-error>
                                    <mat-error *ngIf="createCardForm.get('email').hasError('email')">
                                        Email id is not valid
                                    </mat-error>
                                </mat-form-field>
                                <!-- phone_number field -->
                                <mat-form-field class="w-full">
                                    <mat-label>Phone Number</mat-label>
                                    <input id="phone_number" matInput type="text" [formControlName]="'phone_number'"
                                        class="text-black">
                                    <mat-error *ngIf="createCardForm.get('phone_number').hasError('required')">
                                        This field shouldn't be empty
                                    </mat-error>
                                </mat-form-field>
                            </form>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="max-w-full md:max-w-100 ">
            <div class="flex-col min-w-auto sm:min-w-[400px] p-[30px] sm:p-[36px]"
                style="border: solid 2px #e4e3ff;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border-radius: 30px;">
                <p class="mb-4" style="font-weight:700;font-size:20px;">Summary of Your Contribution</p>
                <div class="flex justify-between items-center mb-2">
                    <p style="font-size:14px;font-weight:400;">Items Total :</p>
                    <div class="text-black flex" style="font-size:15px;font-weight:600;">${{cartDetails?.sub_total}}
                    </div>
                </div>
                <div class="flex justify-between mb-2">
                    <p style="font-size:14px;font-weight:400;">Shipping & Handling :</p>
                    <div class="text-black flex" style="font-size:15px;font-weight:600;">${{cartDetails?.shipping}}
                    </div>
                </div>
                <div class="flex justify-between mb-2">
                    <p style="font-size:14px;font-weight:400;">Sales Tax ({{cartDetails?.tax_percentage}}%) :</p>
                    <div class="text-black flex" style="font-size:15px;font-weight:600;">${{cartDetails?.tax}}</div>
                </div>
                <div class="flex justify-between mb-2" *ngIf="cartDetails?.discount > 0">
                    <p style="font-size:14px;font-weight:400;">Discount :</p>
                    <div class="text-black flex" style="font-size:15px;font-weight:600;color: #dd0d00 !important;">
                        -${{cartDetails?.discount}}</div>
                </div>
                <div class="divider-y"></div>
                <div class="flex justify-between items-center mb-2">
                    <p style="font-size:14px;font-weight:400;">Total Contribution :</p>
                    <div class="text-[#3723dd] flex" style="font-size:20px;font-weight:600;">
                        ${{cartDetails?.cost}}
                    </div>
                </div>

                <p class="text-black mb-2 mt-2" style="font-weight: bold;font-size:14px;">
                    Card Details
                </p>

                <ngx-stripe-card class="mt-2" [options]="cardOptions" (on)="onChange($event)"
                    [elementsOptions]="elementsOptions"></ngx-stripe-card>

                <div class="flex mt-2 items-center gap-2 text-[#afafaf]">
                        <mat-icon [svgIcon]="'heroicons_solid:shield-check'" class="min-h-2 min-w-2 h-4 w-4"></mat-icon>
                        Secure Payment
                </div>

                <p style="font-size:12px;color: #afafaf;" class="mt-1">

                    Your card details are securely processed by Stripe and not stored on our servers. For more about
                    security, visit  <a class="text-primary" href="https://stripe.com/security" target="_blank">Stripe’s Security
                        Page.</a>
                </p>
                <div id="card-errors"></div>
                <!-- Alert -->
                <fuse-alert class="mt-4" *ngIf="showAlert && selectedTab == 1" [appearance]="'outline'"
                    [showIcon]="false" [type]="alert.type">
                    {{alert.message}}
                </fuse-alert>

                <div class="block mt-4 w-full text-center cursor-pointer" (click)="createToken()"
                    style="color:white;background-color:#d50d00;padding:0.67rem 2rem;border-radius:8px;font-weight:500;">
                    PAY ${{cartDetails?.cost}}
                </div>
            </div>

            <div class="flex-col mt-4 min-w-auto sm:min-w-[400px] mb-6"
                style="border: solid 2px #e4e3ff;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border-radius: 30px;padding:36px;">
                <p class="mb-4" style="font-weight:700;font-size:20px;">
                    Order Items ({{cartDetails?.items?.length}})</p>

                <div *ngFor="let item of cartDetails?.items; let last = last">
                    <div class="flex flex-col sm:flex-row" style="gap:26px">
                        <div class="flex flex-col">
                            <div class="h-full"
                                style="background-color:#f5f5f5;border-radius:8px;width:120px;max-height: 180px;overflow: hidden;">
                                <img [src]="item?.designs[0].image"
                                    style="width:100%;height:100%;object-fit: contain; max-height: 200px;"
                                    [alt]="item.title">
                            </div>
                        </div>
                        <div>
                            <h1 class="mb-1" style="font-weight:700;font-size:14px;">{{item.title}}</h1>
                            <p class="mb-2" style="font-size:12px;font-weight:400;">{{item.template_name}}</p>
                            <div class="flex mb-1" *ngFor="let option of item.options_string?.split(' | ')">
                                <p style="font-weight:400;font-size:12px;">{{option}}</p>
                                <!-- <span class="columnSpace">:</span>
                                <p style="font-weight:400;font-size:12px;">{{option.title}}</p> -->
                            </div>
                            <div class="flex">
                                <p style="font-weight:400;font-size:12px;">Quantity</p>
                                <span class="columnSpace">:</span>
                                <p style="font-weight:400;font-size:12px;">{{item.quantity}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="divider-y" *ngIf="!last"></div>
                </div>



            </div>
        </div>
    </div>
</section>