<mat-dialog-content class="p-0" style="height: 100vh;">
    <div class="flex justify-end items-end pl-4 mb-2 sm:mb-0 p-0"
        style="min-height:fit-content !important">
        <!-- <div class="hidden  items-center" (click)="close()">
            <img alt="Left arrow" src="./assets/images/left_arrow.svg"
                style="width:20px;height:20px;object-fit: contain;">
            <span class="ml-3 text-black" style="font-size:14px;">Back</span>
        </div> -->
        <button class="flex ml-8 items-center  justify-end mb-0" mat-button (click)="close()">
            <img alt="Close icon" src="./assets/images/close_icon.svg"
                style="width:17px;height:17px;object-fit: contain;">
        </button>

    </div>
    <div class="h-fit p-0 sm:pl-[20px] sm:pr-[20px] sm:pb-[20px]" style="padding:20px 40px;">
        <div class="flex flex-row" style="gap:20px;">
            <div class="hidden md:block max-w-[190px]">
                <div class="hidden mb-10 items-center cursor-pointer" (click)="close()">
                    <img alt="Left arrow" src="./assets/images/left_arrow.svg"
                        style="width:20px;height:20px;object-fit: contain;">
                    <span class="ml-3 text-black" style="font-size:14px;">Back</span>
                </div>
                <div class="flex flex-col overflow-y-auto overflow-x-hidden gap-2 h-[85vh]"
                    *ngIf="histories?.length > 0">
                    <!-- <div class="w-[100px] h-auto mb-4" style="background-color:#f5f5f5;border-radius:8px;">
                        <img alt="Business card" src="./assets/temp/product1.png"
                            style="width:100%;height:100%;object-fit: contain;">
                    </div> -->
                    <h1 class="mb-2 text-black" style="font-weight:600;font-size:16px;">Version History</h1>
                    <button mat-flat-button class="btn_white w-full mb-3" (click)="createVersionHistory()"
                        *ngIf="!selectedHistory?.is_user_product" style="min-height:45px;">Create New</button>
                    <div *ngFor="let history of histories" (click)="selectedHistory = history" style="width:180px">
                        <div class="w-full h-[100px] history_tab overflow-hidden"
                            [ngClass]="{'active': selectedHistory?.id == history.id}"
                            style="background-color:#f5f5f5;border-radius:8px;cursor: pointer;">
                            <img alt="History preview" [src]="history.thumb"
                                onerror="this.src='./assets/images/placeholder.png'"
                                style="width:100%;height:100%;object-fit: contain;">
                        </div>
                        <div class="flex flex-row gap-2">
                            <div>
                                <p class="mt-2"
                                    style="font-family: 'Poppins', sans-serif;font-size:10px;font-weight:400;line-height: normal;">
                                    Updated on {{ history.last_edited_time | date:'MMM dd yyyy \'at\' h:mm a' }}</p>
                                <!-- <p class="mt-0"
                                    style="font-family: 'Poppins', sans-serif;font-size:10px;font-weight:400;line-height: normal;">
                                    {{version.last_edited_time | date:'h:mm a' }}</p> -->
                            </div>
                            <div>
                                <button mat-icon-button [matMenuTriggerFor]="mainMenu" aria-label="options">
                                    <mat-icon>more_horiz</mat-icon>
                                </button>
                                <mat-menu #mainMenu="matMenu">
                                    <button mat-menu-item (click)="deleteHistory(history?.id)">Delete</button>
                                </mat-menu>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="flex flex-col sm:flex-row  flex-auto min-w-0">
                <div class="flex w-full h-full pl-4 pr-4  sm:pl-6  pt-0 pb-2 sm:pb-4">
                    <div class="w-full  mx-auto " *ngIf="selectedHistory?.designs?.length > 0">
                        <!-- Content starts -->
                        <h1 class="text-black" style="font-weight:700;font-size:20px;line-height: 1;">Edit Your Version
                        </h1>

                        <div class="flex flex-col h-full" *ngIf="histories?.length == 0" style="height: 80vh;">
                            <!-- Order history - Message -->
                            <p class="text-black mb-1 mt-10" style="font-size:18px;font-weight:500;font-family:poppins">
                                There are no histories
                            </p>
                            <p class="text-black" style="font-size:13px;font-weight:normal;font-family:poppins">
                                Sorry, there are no histories available for the selected version at the moment.
                            </p>
                            <div class="flex mt-8">
                                <button mat-flat-button class="block btn_white" (click)="createVersionHistory()"
                                    *ngIf="!selectedHistory?.is_user_product"
                                    style="align-self: flex-start;line-height: normal;min-height: 45px;">
                                    Create New
                                </button>
                            </div>
                            <!-- / -->
                        </div>
                        <!-- <p class="mt-2 mb-0 text-black" style="font-size:12px;font-weight:400;line-height: normal;">Sed
                            ut
                            perspiciatis unde
                            omnis iste natus error sit voluptatem accusantium doloremque</p> -->

                        <div class="flex flex-col sm:flex-row justify-between items-start mb-6 sm:mb-0">
                            <div>
                                <mat-checkbox [(ngModel)]="needToAutofillDetails" class="-ml-[10px] mt-2"
                                    *ngIf="histories?.length > 0 && selectedHistory?.has_design_source && !data?.isForDesignSelection">
                                    Autofill Details
                                </mat-checkbox>

                                <mat-radio-group *ngIf="needToAutofillDetails && !data?.isForDesignSelection"
                                    class="flex flex-row mt-2 -ml-2 mb-2" [color]="'primary'" name="method"
                                    [(ngModel)]="autofillType">
                                    <mat-radio-button [value]="'contacts'">
                                        My Contacts
                                    </mat-radio-button>
                                    <mat-radio-button [value]="'manager'">
                                        Autofill Manager
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <button mat-flat-button class="mt-2 items-center btn_white w-full sm:w-auto"
                                (click)="autofillMyDetails()"
                                *ngIf="histories?.length > 0 && selectedHistory?.has_design_source && !data?.isForDesignSelection">
                                Autofill My Details
                            </button>
                        </div>

                        <div class="p-0 pl-0 mt-11 sm:mt-3 pr-0 sm:pl-12 items-center sm:items-start sm:pr-12 sm:p-6 h-[50vh] sm:h-[60vh] flex flex-row relative bg-[#fff] sm:bg-[#f5f5f5]"
                            style="border-radius:8px;" *ngIf="histories?.length > 0">
                            <div class="relative w-full">
                                <mat-tab-group #tabGroup style="height:100%" class="sm:mt-6 md:mt-0">

                                    <mat-tab *ngFor="let design of selectedHistory?.designs" [label]="design?.title">
                                        <div class="h-[30vh] sm:h-[40vh]">
                                            <img [src]="design.thumb"
                                                onerror="this.src='./assets/images/placeholder.png'"
                                                style="width:100%;height:100%;object-fit: contain;"
                                                alt="Design preview">
                                        </div>
                                    </mat-tab>

                                </mat-tab-group>
                            </div>
                            <div class="absolute edit_btn z-10 flex gap-4 mr-0 sm:mr-4 md:mr-0 -top-[20px]  sm:top-[40px] sm:right-[40px] md:top-[24px] md:right-[45px] right-0"
                                style="right:50px;">
                                <button mat-flat-button class="mt-0 items-center btn_white text-sm"
                                    style="padding:0.2rem 1.3rem !important;min-height:auto;"
                                    *ngIf="selectedHistory?.is_user_product" (click)="deleteDesign()">
                                    Delete
                                </button>
                                <button mat-flat-button class="mt-0 items-center btn_white text-sm"
                                    style="padding:0.2rem 1.3rem !important;min-height:auto;" (click)="openEditor()"
                                    *ngIf="selectedHistory?.has_design_source || selectedHistory?.image == null">
                                    Edit Design
                                </button>
                            </div>
                        </div>
                        <div class="gap-3 flex flex-col sm:flex-row items-center justify-end mt-8"
                            *ngIf="histories?.length > 0">
                            <button mat-flat-button class="w-full sm:w-auto btn_white"
                                [routerLink]="['/', 'organization', 'product', this.data?.versionId, 'lock-fields']"
                                (click)="close()"
                                *ngIf="isOrganizationAccount && selectedHistory?.is_user_product && selectedHistory?.has_design_source && isOrganizationAdmin">
                                <div class="flex gap-3 justify-center items-center">
                                    <img src="./assets/images/employee_icon.svg" alt="Employee icon"
                                        style="width:20px;height:20px;object-fit: contain;">
                                    <span style="color:#292362;font-size:12px;">Lock Fields</span>
                                </div>
                            </button>
                            <button mat-flat-button class="w-full sm:w-auto btn_white"
                                *ngIf="isOrganizationAccount && !data?.isForDesignSelection"
                                (click)="onClickEmployeeAccess()">
                                <div class="flex gap-3 justify-center items-center">
                                    <img src="./assets/images/employee_icon.svg"
                                        style="width:20px;height:20px;object-fit: contain;" alt="Employee icon">
                                    <span style="color:#292362;font-size:12px;">Employee Access</span>
                                </div>
                            </button>
                            <button mat-flat-button class="btn_normal w-full sm:w-auto" (click)="openPlaceYourOrder()"
                                *ngIf="selectedHistory?.is_printing">
                                <div class="flex flex-row gap-3 items-center justify-center">
                                    {{ data?.isForDesignSelection ? 'Select Design' : 'Next'}}<img
                                        src="./assets/images/right_arrow_white.svg" style="width:20px;height:auto"></div>
                            </button>
                            <button mat-flat-button class="btn_white w-full sm:w-auto" (click)="openTransferHistory()"
                                *ngIf="isPWADesigner && !data?.isForDesignSelection">
                                Transfer to Customer</button>
                            <button mat-flat-button class="btn_normal w-full sm:w-auto" (click)="openNewPostPopup()"
                                *ngIf="!selectedHistory?.is_printing && !data?.isForDesignSelection">
                                Share</button>
                        </div>


                        <h1 class="mb-3 mt-8 block md:hidden text-black" style="font-weight:600;font-size:16px;"
                            *ngIf="histories?.length > 0">Version History
                        </h1>
                        <button mat-flat-button class="btn_white w-full block md:hidden mt-4 mb-5"
                            (click)="createVersionHistory()" *ngIf="!selectedHistory?.is_user_product">Create
                            New</button>
                        <div class="flex flex-row gap-6 sm:gap-2 h-auto md:hidden overflow-auto"
                            *ngIf="histories?.length > 0">
                            <div class="w-[130px]" *ngFor="let history of histories"
                                (click)="selectedHistory = history">
                                <div class="w-[130px] h-[100px] border border-[#f5f5f5]"
                                    style="background-color:#f5f5f5;border-radius:8px;">
                                    <img alt="Product Image" [src]="history.thumb"
                                        onerror="this.src='./assets/images/placeholder.png'"
                                        style="width:100%;height:100%;object-fit: contain;">
                                </div>
                                <div class="flex flex-row gap-2">
                                    <div>
                                        <p class="mt-2"
                                            style="font-family: 'Poppins', sans-serif;font-size:10px;font-weight:400;line-height: normal;">
                                            Updated on {{ history.last_edited_time | date:'MMM dd yyyy \'at\' h:mm a' }}
                                        </p>
                                        <!-- <p class="mt-0"
                                            style="font-family: 'Poppins', sans-serif;font-size:10px;font-weight:400;line-height: normal;">
                                            {{version.last_edited_time | date:'h:mm a' }}</p> -->
                                    </div>
                                    <div>
                                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="options">
                                            <mat-icon>more_horiz</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="deleteHistory(history?.id)">Delete</button>
                                        </mat-menu>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Content ends -->

                    </div>

                    <div class="flex flex-col gap-4 items-center h-full w-full min-h-72"
                        *ngIf="selectedHistory?.designs?.length == 0">


                        <p class="text-black text-center sm:text-start mb-1 mt-10"
                            style="font-size:18px;font-weight:500;font-family:poppins">
                            There are no designs for the selected version
                        </p>
                        <p class="text-black text-center sm:text-start"
                            style="font-size:13px;font-weight:normal;font-family:poppins">
                            Oops! It seems there are no designs available for the selected version at the moment. Please
                            create or upload designs by clicking the corresponding buttons below.
                        </p>
                        <div class="flex flex-col w-full justify-center items-center sm:flex-row gap-4">
                            <button class="btn_normal flex items-center w-full sm:w-auto justify-center"
                                (click)="openUploadDesign(true)">
                                <div class="flex flex-row items-center">
                                    <img class="mr-2" alt="Choose-icon" src="./assets/images/choose_icon.svg"
                                        style="width: 20px;height:20px;object-fit: contain;">
                                    Create Design
                                </div>
                            </button>
                            <button class="btn_white flex items-center w-full sm:w-auto justify-center"
                                (click)="openUploadDesign(false)">
                                <div class="flex flex-row items-center"><img class="mr-2" alt="Upload design"
                                        src="./assets/images/upload_icon.svg"
                                        style="width: 20px;height:20px;object-fit: contain;" alt="Upload icon">
                                    Upload Design
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</mat-dialog-content>