<div class="container w-full px-4 md:px-8 sm:pt-4 ml-auto mr-auto pb-12 pt-0" style="max-width:90%">
    <section class="breadcrumb flex mt-2">
        <ul class="flex">
            <li><a href="./home.component.html">Home / Images</a></li>
            <span class="ml-2 mr-2">/</span>
            <li class="font-bold"> Family Stock Images</li>
        </ul>
    </section>
    <!-- banner -->
    <div class="w-full bg-[#632f2f] p-[30px] sm:p-[50px] sm:px-[70px] mt-8" style="border-radius:20px;">
        <h1 class="text-center mb-2 text-white" style="font-weight:700;font-size:23px;">Perspiciatis Unde Omnis Iste
            Natus</h1>
        <p class="m-0 text-white text-center" style="font-weight:400;font-size:14px;">Sed ut perspiciatis unde omnis
            iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque
            ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
        <form class="flex w-full mt-8">
            <input class="w-full h-full px-6 text-black border bg-white" [formControl]="searchControl"
                [placeholder]="'Search millions of quality images'" #barSearchInput
                style="height:40px !important;border-top-left-radius: 6px;border-bottom-left-radius: 10px;">
            <button class="border shrink-0 flex gap-2 items-center px-6 text h-[40px] bg-white"
                style="border-top-right-radius: 10px !important;border-bottom-right-radius: 6px !important;"><img
                    src="./assets/images/search_image_icon.svg" alt="Search by image"
                    style="width:25px;height:25px;object-fit: contain;"><span class="hidden sm:flex text-sm">Search By
                    Image</span></button>
        </form>
    </div>
    <!-- // -->
    <!-- Carousel starts -->
    <div class="flex justify-end gap-3 lg:hidden mt-7">
        <div class="flex">
            <button>
                <div (click)="scrollLeft('business')"
                    style="width:30px;height:30px;padding:6px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                    <img fetchpriority="low" src="assets/images/prev_icon.svg" alt="Business categories scroll left"
                        style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                </div>
            </button>
        </div>
        <div class="flex lg:hidden">
            <button>
                <div (click)="scrollRight('business')"
                    style="width:30px;height:30px;padding:6px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                    <img fetchpriority="low" src="assets/images/next_icon.svg" alt="Business categories scroll right"
                        style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                </div>
            </button>
        </div>
    </div>
    <div class="flex container w-auto lg:w-[105%] lg:ml-[-30px] mt-2 lg:mt-8 p-0 mb-6">
        <div class="hidden lg:flex" style="float: left;">
            <button style="border:0px;background:none;padding: 0px;">
                <div (click)="scrollLeft('business')"
                    style="width:30px;height:30px;padding:6px;margin-left:-20px;margin-right:20px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                    <img fetchpriority="low" src="assets/images/prev_icon.svg" alt="Business categories scroll left"
                        style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                </div>
            </button>
        </div>

        <div #widgetsQuantitySCroll
            class="flex flex-grow-1 overflow-auto carousel_item_gap lg:pl-[7px] lg:pr-[7px] items-center"
            style="padding: 7px;scroll-behavior: auto;gap:10px;scrollbar-width: none;">
            <a class="info-widget relative cursor-pointer font-semibold"
                style="box-shadow:none;font-family: 'Poppins', sans-serif;font-size:14px;border:1px solid #dedeff;border-radius:10px;padding:0.9rem 1.7rem;text-wrap: nowrap;"
                *ngFor="let item of buttonGroup; let i = index" [routerLink]="['/','category', item.categoryId]">
                {{item.text}}
            </a>
        </div>

        <div class="hidden lg:flex" style="float: right;">
            <button style="border:0px;background:none;padding: 0px;">
                <div (click)="scrollRight('business')"
                    style="margin-left:20px;width:30px;height:30px;padding:6px;margin-right:-20px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                    <img fetchpriority="low" src="assets/images/next_icon.svg" alt="Business categories scroll right"
                        style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                </div>
            </button>
        </div>

    </div>
    <!-- // -->
    <h1 class="text-center sm:text-start mb-2 text-black" style="font-weight:700;font-size:23px;">Unique Signature
        Collection </h1>
    <p class="m-0 text-black text-center sm:text-start" style="font-weight:400;font-size:14px;">Sed ut perspiciatis unde
        omnis iste
        natus error sit voluptatem accusantium doloremque laudantium</p>
    <div class="mb-6 mt-6 grid grid-cols-1 sm:grid-cols-3 gap-3">
        <div *ngFor="let item of imageArray">
            <img class="object-cover aspect-[7/5] w-full" [src]="item.image" style="border-radius:10px;" alt="Design preview">
            <div class="grid grid-cols-4 gap-3 mb-12 mt-5">
                <img *ngFor="let item of subImages" class="object-cover aspect-[4/3] w-full" [src]="item.image" alt="Design preview"
                    style="border-radius:10px;">
            </div>
        </div>
    </div>


    <!-- // -->
    <!-- Image list -->
    <h1 class="text-center sm:text-start  mb-2 text-black" style="font-weight:700;font-size:23px;">Most Popular
        Collection </h1>
    <p class="m-0 text-black text-center sm:text-start" style="font-weight:400;font-size:14px;">Sed ut perspiciatis unde
        omnis iste
        natus error sit voluptatem accusantium doloremque laudantium</p>
    <fuse-masonry class="mt-8 flex -mx-2" [items]="imageList" [columns]="masonryColumns"
        [columnsTemplate]="columnsTemplate">
        <!-- Columns template -->
        <ng-template #columnsTemplate let-columns>
            <!-- Columns -->
            <ng-container *ngFor="let column of columns; trackBy: trackByFn" style="margin-left:-0.5rem;">
                <!-- Column -->
                <div class="flex-1 px-2 space-y-4">
                    <ng-container *ngFor="let img of column.items; trackBy: trackByFn">
                        <!-- images -->
                        <img [src]="img.image" style="border-radius:10px;" alt="Preview">
                        <!-- // -->
                    </ng-container>
                </div>
            </ng-container>
        </ng-template>
    </fuse-masonry>
    <!-- // -->
</div>