import { inject } from '@angular/core';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { SubscriptionService } from 'app/core/subscription/subscription.service';
import { Observable, forkJoin } from 'rxjs';

export const subscriptionPlanDataResolver = () => {
    const _subscriptionService = inject(SubscriptionService);
    const _localStorageService = inject(LocalStorageService);

    const requestArray: Observable<any>[] = [_subscriptionService.getSubscriptionPlans("monthly")]
    if (_localStorageService.accessToken?.length > 0) {
        requestArray.push(_subscriptionService.getSubscriptions())
    }
    return forkJoin(requestArray);
};
