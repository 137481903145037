import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { PaymentService } from 'app/core/payment/payment.service';
import { PaymentCard } from 'app/core/payment/payment.types';
import { UserService } from 'app/core/user/user.service';
import { Address } from 'app/core/user/user.types';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { CartService } from 'app/core/cart/cart.service';
import { CartDetails, CartItem, Promocode, ShippingMethod } from 'app/core/cart/cart.types';
import { Router } from '@angular/router';
import { CreateAddressComponent } from '../../settings/address/create-address/create-address.component';
import { CreateCardComponent } from '../../settings/saved-cards/create-card/create-card.component';
import { SubscriptionService } from 'app/core/subscription/subscription.service';
import { PromocodesComponent } from '../../cart/promocodes/promocodes.component';

@Component({
  selector: 'subscription-payment',
  templateUrl: './subscription-payment.component.html',
  styleUrls: ['./subscription-payment.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatTabsModule, MatCheckboxModule, FormsModule, MatRadioModule, MatFormFieldModule, MatInputModule, MatIconModule]
})

export class SubscriptionPaymentComponent implements OnInit {
  addressList: any[];
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  addressList$ = new BehaviorSubject<Address[]>(null);
  cards$ = new BehaviorSubject<PaymentCard[]>(null);
  constructor(
    private _confirmation: FuseConfirmationService, private _spinner: NgxSpinnerService, private _userService: UserService,
    private _toast: ToastrService, private _changeDetectorRef: ChangeDetectorRef, private _matDialog: MatDialog,
    private _cardsService: PaymentService, private _router: Router,
    private _subscriptionService: SubscriptionService) {

  }

  selectedTab = 0

  selectedBillingAddress = null
  selectedCard = null

  showAddressAddButton = false
  showCardAddButton = false

  selectedPlans
  ngOnInit(): void {
    if (sessionStorage.getItem('selectedPlans') == null) {
      this._router.navigate(['/', 'pricing'])
      return
    }

    this._userService.addressList$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(addresses => {
        this.addressList$.next(addresses)
        this.showAddressAddButton = this.addressList$.value.length > 0
        this._changeDetectorRef.markForCheck()
      })

    this._cardsService.savedCards$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(cards => {
        this.cards$.next(cards)
        this.showCardAddButton = this.cards$.value.length > 0
        this._changeDetectorRef.markForCheck()
      })

    this.selectedPlans = Array.from(JSON.parse(sessionStorage.getItem('selectedPlans')));
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  addAddress() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(CreateAddressComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Address added successfully"), "Success");
          this._userService.getAddresses().subscribe()
        }
      });
  }


  showNextTab() {
    switch (this.selectedTab) {
      case 0: {
        if (this.selectedBillingAddress == null) {
          this._toast.error("Please select a billing address")
        }
        else
          this.selectedTab = 1
        break;
      }
      case 1: {
        if (this.selectedCard == null) {
          this._toast.error("Please select a card")
        }
        else {
          this.selectedTab = 2
        }
      }
    }
  }

  removePlan(plan) {
    this.selectedPlans = this.selectedPlans.filter(item => plan.id != item.id)
  }

  addCard() {
    const dialogConfig = new MatDialogConfig();
    this._matDialog.open(CreateCardComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Card added successfully"), "Success");
          // Refresh the keyword list
          //   this.showLoading(true)
          this._cardsService.getCards().subscribe()
        }
      });
  }

  onConfirmAndPay(plan) {
    plan.isSubscribing = true
    plan.error = null
    if (this.selectedBillingAddress && this.selectedCard) {
      let data = {
        "billing_address": this.selectedBillingAddress?.id,
        "card_id": this.selectedCard,
        "plan_id": plan.id
      }

      if (this.promoCode?.plans?.includes(plan.id)) {
        data["coupon_code"] = this.promoCode?.code
      }
      this._spinner.show()
      this._subscriptionService.subscribe(data).subscribe({
        next: (res: any) => {
          this._spinner.hide()
          plan.completed = true
          plan.isSubscribing = false
          plan.can_cancel = true

          if (this.selectedPlans.filter(item => !item.completed).length == 0) {
            sessionStorage.removeItem('selectedPlans')
            sessionStorage.removeItem('planFrequency')
            this._confirmation.open({
              title: "Success",
              message: "Your subscription was successful!",
              icon: { show: false },
              actions: { confirm: { label: "Ok" }, cancel: { show: false } }
            }).afterClosed().subscribe(result => {
              this._router.navigate(['/'])
            })
          }
        },
        error: (err) => {
          this._spinner.hide()
          plan.isSubscribing = false
          plan.error = err ? err.error.message : "Something went wrong. Please try again!"
        }
      });
    }

  }


  completeSubscription() {
    if (this.selectedPlans.filter(item => !item.completed).length == 0) {
      sessionStorage.removeItem('selectedPlans')
      sessionStorage.removeItem('planFrequency')
      this._confirmation.open({
        title: "Success",
        message: "You have subscribed all selected plans!",
        icon: { show: false },
        actions: { confirm: { label: "Ok" }, cancel: { show: false } }
      }).afterClosed().subscribe(result => {
        this._router.navigate(['/'])
      })
      return
    }

    this.selectedPlans.forEach((element, index) => {
      if (!element.completed)
        this.onConfirmAndPay(element);
    });
  }

  promoCode: Promocode
  getPromocodes() {
    this._spinner.show()
    this._subscriptionService.getPromoCodes().subscribe({
      next: (res: Promocode[]) => {
        this._spinner.hide();
        if (res.length == 0)
          this._toast.error("There are no promocodes available now", "Sorry")
        else
          this.showPromocodesModal(res)
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  showPromocodesModal(promocodes: Promocode[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { codes: promocodes, isFromCart: false }
    this._matDialog.open(PromocodesComponent, dialogConfig)
      .afterClosed().subscribe((value: any) => {
        if (value != null) {
          if (value.promocode) {
            this.promoCode = value.promocode
          }
        }
      });
  }

  removePromocode() {
    this.promoCode = null
  }
}
