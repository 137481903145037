<section class="flex-auto p-0 pl-2 pb-6 pt-6 m-auto" style="max-width:90%;">
    <div class="flex flex-col md:flex-row gap-[30px]">
        <div class="flex flex-row relative w-full">
            <div class="relative w-full">
                <mat-tab-group class="mt-4 sm:mt-0" style="flex-grow: 0;" [(selectedIndex)]="selectedTab">
                    <mat-tab label="Billing">
                        <ng-container *ngIf="showAddressAddButton ; else noAddress">
                            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2">
                                <div class="bg-[#f8f7ff] p-6 pl-3 flex items-center w-full gap-3 cursor-pointer"
                                    (click)="selectedBillingAddress = address;" style="border-radius:8px;"
                                    *ngFor="let address of addressList$ | async">

                                    <div class="flex radio-bg rounded-full shrink-0 ">
                                        <div class="radio-selected rounded-full shrink-0"
                                            *ngIf="selectedBillingAddress?.id == address.id">
                                        </div>
                                    </div>
                                    <label class="flex flex-col justify-between items-start w-full cursor-pointer">
                                        <div class="text-black mb-0" style="font-weight: 600;font-size:15px;">
                                            {{address.name}}
                                        </div>
                                        <div class="text-black"
                                            style="font-size:13px;font-weight:400;text-wrap: balance; word-break: break-word;">
                                            <span> {{address.address_line_1}},</span>
                                            <span *ngIf="address.address_line_2?.length > 0">
                                                {{address.address_line_2}},
                                                <br></span>
                                            <span> {{address.state}}, </span>
                                            <span> {{address.city}}, </span>
                                            <span> {{address.zipcode}}</span>
                                        </div>
                                    </label>
                                </div>

                            </div>
                        </ng-container>
                        <div class="divider-y mt-8"></div>
                        <div class="flex gap-0 items-center" *ngIf="showAddressAddButton">
                            <button class="block btn_white" (click)="addAddress()"
                                style="align-self: flex-start;line-height: normal;">
                                Add Address
                            </button>
                            <!-- <mat-radio-button class="mb-0 flex justify-between items-center" [value]="'Standard'"
                                id="radio1">
                            </mat-radio-button>
                            <label style="color:#292362;font-weight:500;font-size:15px;">Add New Address</label> -->
                        </div>
                    </mat-tab>
                    <mat-tab label="Payment" [disabled]="selectedBillingAddress == null">
                        <div class="flex flex-col md:flex-row gap-10">
                            <div class="flex flex-col grow">

                                <ng-container *ngIf="showCardAddButton ; else noCards">
                                    <div class="flex flex-col gap-6">
                                        <div *ngFor="let card of cards$ | async"
                                            class="flex flex-row gap-6 cursor-pointer items-center"
                                            (click)="selectedCard = card.id">
                                            <div class="flex radio-bg rounded-full shrink-0 ">
                                                <div class="radio-selected rounded-full shrink-0"
                                                    *ngIf="selectedCard == card.id">
                                                </div>
                                            </div>
                                            <div class="flex items-center">
                                                <img [src]="'./assets/images/card/'+card.brand+'.png'"
                                                    onerror="this.src='./assets/images/card/credit_card.png'"
                                                    style="width:40px;height:40px;object-fit: contain;" alt="Card image">
                                                <span class="ml-4" style="font-size:12px;">xxxx xxxx xxxx
                                                    {{card.last4}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>


                                <div class="divider-y"></div>
                                <div class="flex gap-0 items-center" *ngIf="showCardAddButton">

                                    <button class="block btn_white" (click)="addCard()"
                                        style="align-self: flex-start;line-height: normal;">
                                        Add Card
                                    </button>
                                </div>
                                <div class="divider-y"></div>
                                <div class="grid grid-cols-1 sm:grid-cols-2 mt-6 gap-4 sm:gap-[60px]" style="gap:60px">
                                    <div>
                                        <p class="text-black mb-2" style="font-weight: 400;font-size:15px;">Billing
                                            Address</p>
                                        <p class="text-black mb-0" style="font-weight: bold;font-size:14px;">
                                            {{selectedBillingAddress?.name}}
                                        </p>
                                        <p class="text-black" style="font-weight: 400;font-size:13px;">
                                            <span> {{selectedBillingAddress?.address_line_1}},</span>
                                            <span *ngIf="selectedBillingAddress?.address_line_2?.length > 0">
                                                {{selectedBillingAddress?.address_line_2}},
                                                <br></span>
                                            <span> {{selectedBillingAddress?.state}}, </span>
                                            <span> {{selectedBillingAddress?.city}}, </span>
                                            <span> {{selectedBillingAddress?.zipcode}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="min-w-auto sm:min-w-[400px]"
                                style="height:fit-content;border-radius: 30px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); border: solid 2px #e4e3ff;padding:36px;">
                                <p class="mb-2" style="font-weight:700;font-size:20px;">Coupon Code</p>
                                <div>
                                    <div class="flex flex-col sm:flex-row gap-2 cursor-pointer"
                                        (click)="getPromocodes()">
                                        <div class="border p-4 pt-2 pb-2 w-full flex items-center justify-between gap-1"
                                            style="border-radius:8px;border:1px solid #343434;height:35px;">
                                            <p>{{promoCode?.code}}</p>
                                            <mat-icon svgIcon="mat_solid:close" *ngIf="promoCode != null"
                                                (click)="$event.stopPropagation();removePromocode()"></mat-icon>
                                        </div>
                                    </div>

                                    <div class="flex justify-between mt-3" *ngIf="promoCode != null">
                                        <p class="w-fit"
                                            style="font-size:12px;font-weight:500;line-height: 1.2;color:#059e75">
                                            {{promoCode.promo_code_info}}
                                        </p>
                                    </div>


                                    <p class="mt-3 cursor-pointer w-fit" (click)="getPromocodes()"
                                        style="font-size:12px;font-weight:500;line-height: 1.2;color:#3723dd">
                                        View All Promo Codes
                                    </p>
                                </div>
                            </div>
                        </div>
                    </mat-tab>

                    <mat-tab label="Complete Order" [disabled]="selectedBillingAddress == null">
                        <div class="flex flex-col gap-3">
                            <ng-container *ngFor="let plan of selectedPlans">
                                <ng-container *ngTemplateOutlet="template; context: {$implicit: plan}"></ng-container>
                            </ng-container>

                            <button mat-flat-button class="btn_normal ml-auto w-auto mt-4"
                                (click)="completeSubscription()">Subscribe
                            </button>
                        </div>
                    </mat-tab>

                </mat-tab-group>
            </div>
            <div class="absolute z-99" style="right:20px;top:10px;" *ngIf="selectedTab != 2">
                <button class="mt-0 items-center mr-2 hidden sm:flex justify-center cursor-pointer"
                    (click)="showNextTab()" style="font-size:14px;color:#292362;font-weight:500;">
                    <div>Next</div>
                    <mat-icon svgIcon="heroicons_outline:chevron-right"
                        style="min-height: 15px; min-width: 15px; height: 15px;width:15px"></mat-icon>
                </button>
            </div>
            <button class="mt-2 items-center flex mr-2 justify-center cursor-pointer sm:hidden absolute z-99"
                (click)="showNextTab()" *ngIf="selectedTab != 2"
                style="font-size:14px;color:#292362;font-weight:500;right:0px">
                <div>
                    Next
                </div>
                <mat-icon svgIcon="heroicons_outline:chevron-right"
                    style="min-height: 15px; min-width: 15px; height: 15px;width:15px"></mat-icon>
            </button>
        </div>
    </div>
</section>

<ng-template #noAddress>
    <!-- Order history - Message -->
    <p class="text-black text-center sm:text-start mb-1" style="font-size:18px;font-weight:500;font-family:poppins">
        You haven't saved any addresses yet
    </p>
    <p class="text-black text-center sm:text-start" style="font-size:13px;font-weight:normal;font-family:poppins">
        Looks like you haven't added any addresses yet. Add a new address to ensure smooth and timely delivery of your orders.
    </p>
    <div class="flex mt-8">
        <button class="block btn_white m-auto sm:ml-0" (click)="addAddress()" mat-flat-button
            style="align-self: flex-start;line-height: normal;">
            Add Address
        </button>
    </div>
    <!-- / -->
</ng-template>
<ng-template #noCards>
    <!-- Order history - Message -->
    <p class="text-black text-center sm:text-start mb-1" style="font-size:18px;font-weight:500;font-family:poppins">
        You haven't saved any cards yet
    </p>
    <p class="text-black text-center sm:text-start" style="font-size:13px;font-weight:normal;font-family:poppins">
        Oops! It seems you haven't saved any cards yet. Add a new card to make payments hassle-free and secure
    </p>
    <div class="flex mt-8">
        <button class="block btn_white m-auto sm:ml-0" (click)="addCard()" mat-flat-button
            style="align-self: flex-start;line-height: normal;">
            Add Card
        </button>
    </div>
    <!-- / -->
</ng-template>

<ng-template #template let-plan>
    <div class="p-[30px]"
        style="border: solid 1px #dedeff;box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.16);border-radius:20px">
        <div class="flex flex-col-reverse  md:flex-row justify-between">
            <div class="grid grid-cols-1 sm:grid-cols-2 items-center" style="flex-grow:1">
                <p class="text-[#060267] sm:col-span-2" style="font-size:15px;font-weight:700;">
                    {{plan.title}}</p>
                <div class="mb-2 text-black flex flex-row items-start gap-4 mt-2 *ngIf=plan.features.length > 0"
                    style="font-weight:400;font-size:14px;" *ngFor="let feature of plan.features">
                    <div class="shrink-0 mt-[5px]"
                        style="width:10px;height:10px;border-radius:50px;background-color: #5040dd;">
                    </div>
                    <div class="text-black" style="font-size:14px;">
                        {{feature}}
                    </div>
                </div>
            </div>
            <div
                class="flex items-start sm:items-center flex-col sm:flex-row gap-4 sm:gap-8 mb-4 md:mb-0 justify-between md:justify-end shrink-0">
                <div class="flex flex-row items-center gap-8">
                    <div class="bg-[#ece5ff] shrink-0" *ngIf="plan.offer_percentage>0"
                        style="font-size:14px;color:#3723dd;border-radius:30px;padding:8px 20px">
                        {{plan.offer_percentage}}% Off</div>
                    <div>
                        <p class="text-[#3723dd] plantype_text" style="font-size:30px;font-weight:700;line-height: 1;">
                            <span *ngIf="(!plan.custom && plan.cost !='Free')">
                                $</span>
                            {{plan.cost}}
                            <span *ngIf="(!plan.custom && plan.cost !='Free' && plan.payment_type != '')"
                                style="font-size: 20px;">
                                /{{(plan.payment_type=='monthly' ? 'mo' : plan.payment_type)}}
                            </span>
                        </p>
                        <p class="text-[#3723dd] mb-1"
                            *ngIf="!plan.custom && plan.cost !='Free' && plan.offer_percentage > 0"
                            style="font-size:12px;font-weight:700;text-decoration: line-through;">
                            <span *ngIf="(!plan.custom && plan.cost !='Free')">
                                $</span>{{plan.original_price}}
                            <span *ngIf="(!plan.custom && plan.cost !='Free' && plan.payment_type != '')">
                                /{{(plan.payment_type=='monthly' ? 'mo' : plan.payment_type)}}
                            </span>
                        </p>
                    </div>
                </div>
                <div class="hidden md:flex">


                    <button mat-flat-button class="w-full md:mr-[30px] mr-0 btn_white" (click)="removePlan(plan)"
                        *ngIf="!plan.completed && !plan.isSubscribing">
                        Remove
                    </button>

                    <div class="w-full md:mr-[30px] mr-0 btn_normal" *ngIf="!plan.completed && plan.isSubscribing">
                        Processing
                    </div>

                    <div class="w-full md:mr-[30px] mr-0 btn_normal" *ngIf="plan.completed">
                        <div class="flex items-center justify-center gap-3">
                            <!-- <img *ngIf="!plan.custom && !plan.subscribed && !plan.selected"
                        src="./assets/temp/add_icon.svg" style="width:14px;height:14px;object-fit: contain;"> -->
                            <span style="font-size:12px;font-weight:600;">
                                Subscribed
                            </span>
                        </div>
                    </div>

                    <button mat-flat-button class="w-full md:mr-[30px] mr-0 btn_normal" (click)="onConfirmAndPay(plan)"
                        *ngIf="plan.error">
                        <span style="font-size:12px;font-weight:600;">
                            Retry
                        </span>
                    </button>
                </div>
            </div>

        </div>
        <div class="w-full sm:w-auto flex md:hidden justify-end">

            <button mat-flat-button class="w-full sm:w-auto md:mr-[30px] mr-0 mt-4 btn_white" (click)="removePlan(plan)"
                *ngIf="!plan.completed && !plan.isSubscribing">
                Remove
            </button>

            <div class="w-full sm:w-auto md:mr-[30px] mr-0 mt-4 btn_normal"
                *ngIf="!plan.completed && plan.isSubscribing">
                Processing
            </div>

            <div class="w-full sm:w-auto md:mr-[30px] mr-0 mt-4 btn_normal" *ngIf="plan.completed">
                <div class="flex items-center justify-center gap-3">
                    <!-- <img *ngIf="!plan.custom && !plan.subscribed && !plan.selected"
                        src="./assets/temp/add_icon.svg" style="width:14px;height:14px;object-fit: contain;"> -->
                    <span style="font-size:12px;font-weight:600;">
                        Subscribed
                    </span>
                </div>
            </div>

            <button mat-flat-button class="w-full sm:w-auto md:mr-[30px] mr-0 mt-4 btn_normal"
                (click)="onConfirmAndPay(plan)" *ngIf="plan.error">
                <span style="font-size:12px;font-weight:600;">
                    Retry
                </span>
            </button>

        </div>
        <div *ngIf="plan.error" class="mt-3" style="color: red;">
            {{plan.error}}
        </div>
    </div>
</ng-template>