import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ImageViewerService } from 'app/modules/admin/image-viewer/image-viewer.service';
@Component({
    selector: 'pwa-image-carousel',
    templateUrl: './image-carousel.component.html',
    styleUrls: ['./image-carousel.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule,
        MatIconModule,
        MatButtonModule,]

})
export class ImageCarouselComponent implements OnInit {

    constructor(private _imageViewer: ImageViewerService) { }
    // @Output() onClose = new EventEmitter<void>();

    // close() {
    //   this.onClose.emit();
    // }
    @Input() bgColor : string

    productImages
    @Input() set images(images: string[]) {

        this.productImages = images
        if (images?.length > 0)
            this.currentImage = images[0]
        else
        this.currentImage = null
    }

    currentImage = null
    interval = null
    ngOnInit(): void {

    }
    changeBackgroundCounter = 0
    onMouseEnter() {
        //     console.log("Mouse Enter");
        //     this.interval = setInterval(() => {
        //         this.changeBackgroundCounter++;
        //         if (this.changeBackgroundCounter > this.product?.images?.length - 1) {
        //             this.changeBackgroundCounter = 0;
        //         }
        //         this.imgSrc = this.product?.images[this.changeBackgroundCounter];
        //     }, 2000)

    }

    onMouseLeave() {
        //     //     clearInterval(this.interval);
    }


    ngOnDestroy() {
        // clearInterval(this.interval);
    }

    onclickIndicator(index) {
        this.changeBackgroundCounter = index;
        this.currentImage = this.productImages[this.changeBackgroundCounter]
    }

    openImage(image) {
        this._imageViewer.open(image)
    }

}
