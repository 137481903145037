<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">
    <!-- Bread crumb -->
    <div class="breadcrumb flex mt-4 mb-4 sm:mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <p class="text-black">Digital Products</p>
        <span class="ml-2 mr-2">/</span>
        <a class="text-black" style="font-weight:600" href="/socialmedia-services">Social Media Services</a>
    </div>
    <!-- / -->
    <!-- Detail page -->
    <div class="flex flex-col-reverse md:flex-row items-center gap-8 sm:gap-20  w-full bg-[#e2f1ff] pt-[30px] pl-[20px]  pr-[20px] sm:pr-[50px] sm:pb-0 sm:pl-[50px]"
        style="border-radius:20px;">
        <div>
            <img class="h-[170px] sm:h-[220px]" src="./assets/images/ssm_service_banner.png"
                style="max-width:fit-content;height:220px;width: auto;" alt="Social media services">
        </div>
        <div>
            <h1 style="font-weight:700;font-size:23px;">Social Media Services</h1>
            <p class="mt-2 mb-2 text-black" style="font-weight:400;font-size:14px;">
                Sed ut perspiciatis unde omnis
                iste natus error sit voluptatem accusantium doloremque laudantium,
                totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
                dicta
                sunt explicabo.</p>
        </div>
    </div>


    <div class="mt-16">
        <h1 style="font-weight:700;font-size:18px;">Affordable plans to meet your needs</h1>
        <p class="mt-2 mb-2 text-black" style="font-weight:400;font-size:14px;">
            We provide high-quality social media services on all platforms to expedite growth and expand your marketing
            potential.
        </p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 mt-12 gap-12 sm:gap-8 md:gap-2">

        <div class="relative flex" *ngFor="let plan of socialMedia">
            <ng-container
                *ngTemplateOutlet="planView; context: {$implicit: {plan: plan, fullWidth : false}}"></ng-container>
        </div>

    </div>
    <div>
        <p class="text-black m-auto mb-16 mt-16 text-center" style="font-size:14px;">
            Level 1-3 are online support only, Want to see more?
        </p>
    </div>

    <div class="grid grid-cols-1 gap-12  mb-6">
        <div class="relative flex" *ngFor="let plan of postingHostingAndPromotion">
            <ng-container
                *ngTemplateOutlet="planView; context: {$implicit: {plan: plan, fullWidth : true}}"></ng-container>
        </div>
    </div>
    <div class="mt-16 mb-16">
        <h1 style="font-weight:700;font-size:18px;">Facebook, Instagram & Twitter Advertising Management</h1>
        <p class="mt-2 mb-2 text-black" style="font-weight:400;font-size:14px;">
            We offer many marketing strategies and branding services to expand your brand’s digital reach and grow your
            online presence. We also provide quality targeting information such as demographics and user interests to
            maximize sales and leads.
            See our packages below.
        </p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-12 sm:gap-8 md:gap-4  mb-6">
        <div class="relative flex" *ngFor="let plan of blogs">
            <ng-container
                *ngTemplateOutlet="planView; context: {$implicit: {plan: plan, fullWidth : false}}"></ng-container>
        </div>
    </div>


    <div class="grid grid-cols-1 gap-12  mb-6 mt-16">
        <div class="relative flex" *ngFor="let plan of seo">
            <ng-container
                *ngTemplateOutlet="planView; context: {$implicit: {plan: plan, fullWidth : true}}"></ng-container>
        </div>
    </div>

</section>

<ng-template #planView let-data>
    <div class="absolute text-white" *ngIf="data.plan.featured"
        style="border-radius:25px;background-color: red;top:-37px;padding:10px 20px;height:80px">Most popular
    </div>
    <div class="p-[40px] flex flex-col h-full"
        style="border: solid 1px #dedeff;border-radius: 30px;box-shadow: 0 8px 6px 0 rgba(0, 0, 0, 0.16);background-color:#fbfaff;z-index: 2; ">
        <div class="flex flex-row gap-3 mb-2 items-center">
            <!-- <img [src]="data.plan.image" style="width:30px;height:30px;object-fit: contain;"> -->
            <span class="text-[#060267] font-bold" style="font-size:20px">
                {{data.plan.title}}
            </span>
        </div>
        <p class="text-[#3723dd] font-bold" style="font-size:35px;">
            <span>${{data.plan.cost}}</span>
            <span style="font-size:20px;">{{data.plan.payment_type == "Monthly" ? '/mo' : '/year'}}</span>
        </p>
        <p class="text-black">
            <span>{{data.plan.additional_terms}}</span>
        </p>
        <div class="mb-6" style="flex-grow:1;">
            <div *ngFor="let feature of data.plan.features; let last = last">
                <p class="mt-2 mb-2 text-black" style="font-weight:400;font-size:14px;">
                    {{feature}}
                </p>
                <div class="border-bottom" *ngIf="!last"></div>
            </div>
        </div>
        <div>
            <button class="flex items-center w-full justify-center" (click)="onChoosePlan(data.plan.id)"
                [ngClass]="data.fullWidth ? 'sm:w-auto' : 'sm:w-full'"
                style="color:white;background-color:#3723dd;border:1px solid #3723dd;padding:0.67rem 3rem;border-radius:8px;font-weight:bold;font-size:12px;">
                {{data.fullWidth ? 'Contact Us to Explore' : 'Choose This Plan'}}
            </button>
        </div>
    </div>
</ng-template>