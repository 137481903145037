<section class="flex-auto px-4 md:px-8 sm:pt-4 m-auto pb-12 pt-0" style="max-width:90%;">
    <!-- Bread crumb -->
    <div class="breadcrumb flex mt-4 mb-4 sm:mb-8">
        <a class="text-black" [routerLink]="['/','home']">Home</a>
        <span class="ml-2 mr-2">/</span>
        <p style="font-weight:600">Cart</p>
    </div>
    <!-- / -->
    <!-- Detail page -->
    <ng-container *ngIf="cartItems.length > 0 ; else noData">
        <div class="flex flex-col lg:flex-row justify-between" style="gap:20px">
            <div class="flex flex-col w-full">
                <div class="flex flex-col" *ngFor="let item of cartItems; let last = last">
                    <div class="flex flex-col sm:flex-row" style="gap:40px">
                        <div class="flex flex-col w-full sm:w-72 shrink-0">
                            <div class="block sm:hidden cursor-pointer" style="margin-left:auto;margin-bottom:20px;"
                                (click)="deleteCartItem(item)">
                                <img src="./assets/images/close-red.svg" style="width:14px;height:14px;">
                            </div>
                            <div class="w-full h-full max-h-56 overflow-hidden"
                                style="background-color:#f5f5f5;border-radius:8px;border:1px solid #f5f5f5;">
                                <img [src]="item.image" class="w-full h-full object-contain  max-h-56">
                            </div>
                            <div class="flex gap-6 mt-3">
                                <button mat-flat-button class="flex mt-2 items-center btn_white"
                                    *ngIf="!item.review_completed" (click)="getDetailsForReview(item)"
                                    style="padding:0.5rem 1.2rem !important;">
                                    Review Product
                                </button>
                                <button mat-flat-button class="flex mt-2 items-center btn_white"
                                    *ngIf="item.has_design_source && item.can_clone"
                                    [routerLink]="['/', 'cart','clone', item.id]"
                                    style="padding:0.5rem 1.2rem !important;">
                                    Clone Product
                                </button>
                            </div>
                        </div>
                        <div class="grow">
                            <h1 class="mb-1" style="font-weight:700;font-size:20px;">{{item.title}}</h1>
                            <p class="mb-2" style="font-size:14px;font-weight:400;">{{item.template_name}}</p>
                            <div class="flex mb-1" *ngFor="let option of item.options">
                                <p style="font-weight:400;font-size:14px;">{{option.type}}</p>
                                <span class="columnSpace">:</span>
                                <p style="font-weight:400;font-size:14px;">{{option.title}}</p>
                            </div>

                            <div class="flex mb-4 mt-3 items-center">
                                <p style="font-weight:400;font-size:14px;">Qty</p>
                                <span class="columnSpace">:</span>
                                <!-- <p style="font-weight:400;font-size:14px;">500</p> -->
                                <mat-form-field class="-mt-[24px] -mb-[20px]">
                                    <mat-label></mat-label>
                                    <mat-select [(value)]="item.selectedQuantity"
                                        (selectionChange)="onQuantityChange(item, $event.value)">
                                        <mat-option *ngFor="let qty of item.quantity_options"
                                            [value]="qty.id">{{qty.quantity}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <!-- <button class="flex mt-2 items-center"
                                style="background-color:#fff;padding:0.5rem 1.2rem;border-radius:8px;font-weight:500;border: 1px #ddd;color:#292362;font-size:12px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.13);">
                                Edit Options
                            </button> -->
                        </div>
                        <div class="flex flex-col sm:m-auto shrink-0">
                            <p style="font-weight:400;font-size:14px;">Total</p>
                            <p style="font-weight:600;font-size:20px;">${{item.price | number:'1.0-2'}}</p>
                        </div>
                        <div class="hidden sm:block cursor-pointer">
                            <img src="./assets/images/close-red.svg" style="width:14px;height:14px;"
                                (click)="deleteCartItem(item)" alt="Close icon">
                        </div>
                    </div>
                    <div class="divider-y" *ngIf="!last"></div>
                </div>

            </div>
            <div class="min-w-auto sm:min-w-[400px]"
                style="height:fit-content;border-radius: 30px;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); border: solid 2px #e4e3ff;padding:36px;">
                <p class="mb-2" style="font-weight:700;font-size:20px;">Summary</p>
                <div>
                    <label for="categories text-black" class="block mb-2 mt-2">Enter Your Coupon Code</label>
                    <div class="flex flex-col sm:flex-row gap-2">
                        <input class="border p-4 pt-2 pb-2 w-full text-black" [(ngModel)]="promocode"
                            [placeholder]="'Enter Your Code'" name="promocodeInput"
                            style="border-radius:8px;border:1px solid #343434;height:45px;">
                        <button mat-flat-button class="btn_normal p-4 pl-6 pr-6 flex items-center justify-center"
                            style="min-height:45px;line-height: normal;" (click)="applyPromocode()">
                            Apply
                        </button>
                    </div>
                    <p class="mt-1 w-fit" *ngIf="promocodeError?.length > 0"
                        style="font-size:12px;font-weight:500;line-height: 1.2;color:#dd0d00">
                        {{promocodeError}}
                    </p>

                    <div class="flex justify-between mt-3" *ngIf="cartDetails?.promo_code.length > 0">
                        <p class="w-fit" style="font-size:12px;font-weight:500;line-height: 1.2;color:#059e75">
                            {{cartDetails.promo_code_info}}
                        </p>

                        <p class="cursor-pointer w-fit"
                            style="font-size:12px;font-weight:500;line-height: 1.2;color:#dd0d00"
                            (click)="removePromocode()">
                            Remove Code
                        </p>
                    </div>


                    <p class="mt-3 cursor-pointer w-fit" (click)="getPromocodes()"
                        style="font-size:12px;font-weight:500;line-height: 1.2;color:#3723dd">
                        View All Promo Codes
                    </p>
                    <p class="mb-2 mt-4 text-black hidden" style="font-weight:600;font-size:17px;">Shipping Method</p>
                    <!-- <mat-radio-group style="margin-left:-10px !important;" class="flex flex-col pl-0 pr-0 hidden"
                        [color]="'primary'" [value]="'just-good'">
                        <div class="flex flex-row">
                            <mat-radio-button class="mb-0 flex justify-between items-center" [value]="'Standard'"
                                id="radio1">

                            </mat-radio-button>
                            <label for="radio1" class="flex flex-row justify-between items-center w-full">
                                <div>
                                    Standard
                                    <span style="font-size:13px;">
                                        (by December 08)
                                    </span>
                                </div>

                                <div class="text-black flex" style="font-size:15px;font-weight:600;">$17.99</div>
                            </label>
                        </div>
                        <div class="flex flex-row">
                            <mat-radio-button class="mb-0 flex justify-between items-center" [value]="'Standard'"
                                id="radio1">

                            </mat-radio-button>
                            <label for="radio1" class="flex flex-row justify-between items-center w-full">
                                <div>
                                    Quick
                                    <span style="font-size:13px;">
                                        (by December 04)
                                    </span>
                                </div>
                                <div class="text-black flex" style="font-size:15px;font-weight:600;">$35.77</div>
                            </label>

                        </div>
                        <div class="flex flex-row">
                            <mat-radio-button class="mb-0 flex justify-between items-center" [value]="'Standard'"
                                id="radio1">

                            </mat-radio-button>

                            <label for="radio1" class="flex flex-row justify-between items-center w-full">
                                <div>
                                    Super Quick
                                    <span style="font-size:13px;">
                                        (by December 02)
                                    </span>
                                </div>
                                <div class="text-black flex" style="font-size:15px;font-weight:600;">$46.77</div>
                            </label>
                        </div>

                    </mat-radio-group> -->
                    <div class="divider-y-form"></div>
                    <div class="flex justify-between items-center mb-2">
                        <p style="font-size:14px;font-weight:400;">Sub Total :</p>
                        <div class="text-black flex" style="font-size:15px;font-weight:600;">${{cartDetails.sub_total}}
                        </div>
                    </div>
                    <div class="flex justify-between mb-2">
                        <p style="font-size:14px;font-weight:400;">Shipping Charge :</p>
                        <div class="text-black flex" style="font-size:15px;font-weight:600;">${{cartDetails.shipping}}
                        </div>
                    </div>
                    <div class="flex justify-between mb-2">
                        <p style="font-size:14px;font-weight:400;">Tax ({{cartDetails.tax_percentage}}%) :</p>
                        <div class="text-black flex" style="font-size:15px;font-weight:600;">${{cartDetails.tax}}</div>
                    </div>
                    <div class="flex justify-between mb-2" *ngIf="cartDetails.discount > 0">
                        <p style="font-size:14px;font-weight:400;">Discount :</p>
                        <div class="text-black flex" style="font-size:15px;font-weight:600;color: #dd0d00 !important;">
                            -${{cartDetails.discount}}</div>
                    </div>
                    <div class="divider-y-form"></div>
                    <div class="flex justify-between items-center mb-2">
                        <p style="font-size:14px;font-weight:400;">Total :</p>
                        <div class="text-black flex" style="font-size:15px;font-weight:600;">
                            ${{cartDetails.total_price}}
                        </div>
                    </div>
                    <button mat-flat-button class="block btn_red mt-4 w-full text-center cursor-pointer" (click)="checkout()">
                        Checkout</button>

                </div>
            </div>
        </div>
    </ng-container>
    <!-- / -->

</section>

<ng-template #noData>
    <!-- Order history - Message -->
    <p class="text-black text-center sm:text-start mb-1 mt-6" style="font-size:18px;font-weight:500;font-family:poppins">
        You haven't added anything to the cart.
    </p>
    <p class="text-black text-center sm:text-start" style="font-size:13px;font-weight:normal;font-family:poppins">
        Your cart is currently empty. Start browsing our wide selection of templates and add the ones you like to your cart to proceed with your purchase.
    </p>
    <div class="flex mt-8 mb-6">
        <button mat-flat-button class="block btn_white m-auto sm:ml-0" [routerLink]="'/'" style="align-self: flex-start;line-height: normal;">
            Continue Shopping
        </button>
    </div>
    <!-- / -->
</ng-template>