<div class="flex flex-col flex-auto min-w-0">
    <!-- Banner starts -->
    <section style="background-image:url(./assets/images/banner_bg.webp);background-size:100% 100%;">
        <div class="row block sm:flex px-0 lg:px-8 pt-4 m-auto max-w-full lg:max-w-[90%]">
            <div class="hidden lg:block"
                style="min-width:300px;border-radius:15px;box-shadow: 0 -6px 6px 0 rgba(0, 0, 0, 0.16);">
                <ul>
                    <li class="pl-6 pt-3 pb-3"
                        style="background-color:#393085;color:white;font-weight:500;border-top-left-radius: 15px;border-top-right-radius:15px;">
                        Business Categories</li>
                    <li *ngFor="let category of businessCategories;last as isLast" class="border-b">
                        <a class="category_btn flex  gap-4 pl-6 pt-2 pb-2 border-gray-300  bg-white"
                            style="cursor:pointer" [routerLink]="['/','global-search']"
                            [queryParams]="{'franchise_category':category.id}">
                            <img fetchpriority="low" ngSrc="{{category.image}}" width="20" height="20" [alt]="category.name"
                                style="width:20px;height:20px;object-fit: contain;"><span class="truncate text-black"
                                style="font-size:0.875rem;">
                                {{category.name}}</span>
                        </a>
                    </li>
                    <li>
                        <div class="category_btn flex  gap-4 pl-6 pt-2 pb-2 border-gray-300  bg-white"
                            (click)="openAndMorePopup()" style="cursor:pointer">
                            <img fetchpriority="low" width="17" height="17" ngSrc="./assets/images/andmore.svg" alt="Show more categories"
                                style="width:17px;height:17px;"><span>
                                and More</span>
                        </div>
                    </li>

                </ul>
            </div>
            <div class="w-full pl-8 pr-8 lg:pl-6 lg:pr-0 pb-8 flex flex-col" style="height:auto;">
                <!-- <div  id="controls-carousel" data-carousel="slide"
                    class="relative w-full h-auto mb-0 mt-0 sm:mb-12 md:mb-4 sm:mt-6 md:mt-0 flex-grow-1">
                    
                    <div class="relative h-full overflow-hidden rounded-0 lg:rounded-lg" style="min-height:400px;"> -->
                <!-- Item 1 -->
                <div class="relative duration-700 ease-in-out flex flex-row justify-center" style="min-height:400px;"
                    *ngFor="let banner of banners; let index = index" [id]="'carousel-item-'+index">
                    <img fetchpriority="low" class="hidden md:flex mr-2" width="270" height="100" [ngSrc]="banner.image"
                        style="width:270px;height:100%;object-fit: contain;" alt="Banner image">
                    <div class="pl-3 pr-4 pt-0 sm:pt-0 pb-0 flex-grow m-auto">
                        <h1 class="text-center mb-2 m-auto lg:-ml-[70px] lg:-mr-[70px]"
                            style="color:#393085;font-weight:bold;font-size:28px;">
                            {{banner.title}}
                        </h1>
                        <p class="text-center m-auto  lg:-ml-[60px] lg:-mr-[60px]" style="color:black;">
                            {{banner.description}}
                        </p>
                        <p class="text-center m-auto" style="color:black;max-width:500px;" *ngIf="banner.code">
                            USE CODE: <span style="color: #67bfff;">{{banner.code}}</span>
                        </p>
                        <button class="flex items-center m-auto mt-4 btn_red" (click)="onClickBanner(banner)"
                            mat-flat-button style="font-weight:600 !important">
                            {{banner.button_title}}
                        </button>
                    </div>
                    <img fetchpriority="low" class="hidden md:flex ml-2"  width="270" height="100" [ngSrc]="banner.second_image"
                        style="width:270px;height:100%;object-fit: contain;" alt="Banner image">
                </div>
                <!-- </div> -->

                <!-- <div class="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
                        <button *ngFor="let banner of banners; let index = index" [id]="'carousel-indicator-'+index"
                            type="button" class="w-3 h-3 rounded-full shadow-lg shadow-[#5040dd]" aria-current="true"
                            aria-label="Slide 0"></button>
                    </div> -->


                <!-- </div> -->

                <div>
                    <div class="grid grid-cols-2 mt-4 sm:mt-2 sm:grid-cols-2 md:grid-cols-4 gap-2">
                        <div class="bg-white p-4 pt-4 sm:pt-6 pb-4" style="border-radius:8px;"
                            *ngFor="let category of bannerCategories">
                            <a [routerLink]="category.link"><img fetchpriority="low"  width="200" height="130" [ngSrc]="category.image"
                                    [alt]="category.name" class="m-auto"
                                    style="width:200px;height:130px;object-fit: contain;">
                                <p class="text-center mt-3 card_title" style="font-size:12px;">{{category.title}}
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="flex lg:hidden">
                    <div class="underline m-auto mt-6 mb-4" (click)="openAndMorePopup()"
                        style="font-size:14px;color:#393085;font-weight:bold;cursor: pointer;">View Business
                        Categories</div>
                </div>

            </div>
        </div>
    </section>
    <!-- End / -->
    <!-- Main -->
    <div class="flex-auto  px-4 md:px-8 pt-4 m-auto" style="max-width:90%;">

        <!-- CONTENT GOES HERE -->
        <div class="rounded-2xl">

            <section class="bg-white">
                <!-- Explore Images & Videos -->
                <p class="hidden title_sm mt-8 mb-6 text-left"
                    style="color:black;font-family: 'Poppins', sans-serif;font-size:23px;font-weight:bold;">
                    Explore Our Creative Services</p>
                <div class="hidden grid-cols-2 sm:grid-cols-4 gap-2">
                    <div class="card h-full w-full overflow-hidden"
                        *ngFor="let category of featuredCategories; let i = index" style="border-radius:8px;">
                        <img fetchpriority="low" [src]="category.image" [alt]="category.title" class="h-[170px] sm:h-[240px]"
                            style="border:1px solid #f5f5f5;width:100%;height:100%;object-fit: contain;border-radius:8px; background-color: #f5f5f5;">
                        <p class="pt-3 mb-3" style="font-family: 'Poppins', sans-serif;font-size:14px;font-weight:400;">
                            {{category.title}}</p>
                    </div>
                </div>
                <!-- first Section -->
                <div class="grid grid-cols-1 md:grid-cols-2 gap-2 pt-6">
                    <div class="bg-white pl-6 sm:pl-12 pr-6 sm:pr-20 pt-12 pb-12 sm:pt-12 md:pt-8 sm:pb-12 md:pb-8 text-left flex flex-col justify-center"
                        style="background-image: url(./assets/images/chooseDesign.png),url(./assets/images/chooseDesign@2x.png),url(./assets/images/chooseDesign@3x.png);background-size:cover;background-repeat:no-repeat;border-radius: 8px;background-position: top,right;">
                        <p
                            style="color:#060267;font-family: 'Poppins', sans-serif;font-size:23px;font-weight:bold;max-width:250px;line-height:1.3">
                            {{'home.autofill-manager.title' | transloco}}
                        </p>
                        <p class="mt-2"
                            style="font-family: 'Poppins', sans-serif;font-size:14px;font-weight:400;max-width:280px;">
                            {{'home.autofill-manager.description' | transloco}} </p>
                        <button class="block w-auto mt-4 m-left btn_normal max-w-fit" mat-flat-button
                            (click)="openAutofillManager()"
                            style="border-radius:7px;">
                            {{'home.autofill-manager.button' | transloco}}
                        </button>
                    </div>
                    <div class="bg-white pl-6 sm:pl-12 pr-6 sm:pr-20 pt-12 pb-12 sm:pt-12 md:pt-8 lg:pt-12 lg:pb-12  sm:pb-12 md:pb-8 text-left flex flex-col justify-center items-start"
                        style="background-image: url(./assets/images/hireYourDesigner_bg@3x.webp);background-size:cover;border-radius: 8px;background-position: top,right;">
                        <p
                            style="color:#060267;font-family: 'Poppins', sans-serif;font-size:23px;font-weight:bold;max-width:230px;line-height: 1.3;">
                            Looking for
                            Designer Support?</p>
                        <p class="mt-2"
                            style="font-family: 'Poppins', sans-serif;font-size:14px;font-weight:400;max-width:330px;">
                            Hire a dedicated designer today for
                            professional guidance and personalized
                            attention. Let’s bring your ideas to life together.</p>
                        <a (click)="openDesignerRequirementModal()"
                            class="w-auto mt-4 m-left btn_normal cursor-pointer">Hire Your Designer</a>
                    </div>
                </div>
                <!-- End first section -->
                <!-- Brand Your Business with Printing Products -->
                <div class="flex mt-8 mb-8" #viewWidth>
                    <p class="grow title_sm mt-4 mb-3 text-left"
                        style="color:black;font-family: 'Poppins', sans-serif;font-size:23px;font-weight:bold;">Brand
                        Your Business with Printing Products</p>
                    <div class="flex justify-end gap-3">
                        <div class="flex lg:hidden">
                            <button>
                                <div (click)="scrollLeft('business')"
                                    style="width:30px;height:30px;padding:6px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                                    <img fetchpriority="low" src="assets/images/prev_icon.svg"
                                        alt="Business categories scroll left"
                                        style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                                </div>
                            </button>
                        </div>
                        <div class="flex lg:hidden">
                            <button>
                                <div (click)="scrollRight('business')"
                                    style="width:30px;height:30px;padding:6px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                                    <img fetchpriority="low" src="assets/images/next_icon.svg"
                                        alt="Business categories scroll right"
                                        style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="flex container w-auto lg:w-[105%] lg:ml-[-30px]"
                    [ngStyle]="{'margin-left.px': difference, 'margin-right.px': difference}"
                    style="padding:0px;margin-bottom:4rem;">
                    <div class="hidden lg:block" style="float: left;margin-top:70px;">
                        <button style="border:0px;background:none;padding: 0px;margin-top: 7px;">
                            <div (click)="scrollLeft('business')"
                                style="width:30px;height:30px;padding:6px;margin-left:-20px;margin-right:20px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                                <img fetchpriority="low" src="assets/images/prev_icon.svg"
                                    alt="Business categories scroll left"
                                    style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                            </div>
                        </button>
                    </div>

                    <div #widgetsQuantitySCroll
                        class="flex flex-grow-1 overflow-auto carousel_item_gap lg:pl-[7px] lg:pr-[7px]"
                        style="padding: 7px;scroll-behavior: auto;gap:50px;scrollbar-width: none;"
                        [ngStyle]="{'padding-left.px': innerDifference, 'padding-right.px': innerDifference}">
                        <a class="info-widget relative" style="cursor:pointer;box-shadow:none;"
                            *ngFor="let item of printingIcons; let i = index"
                            [routerLink]="['/','category', item.categoryId]">
                            <div style="border:2px solid #5040dd;padding:7px;border-radius: 100%;">
                                <div class="flex flex-column items-center w-[90px] h-[90px] md:w-[140px] md:h-[140px]"
                                    style="padding:0;width: max-content;border-radius:100%;background-color:#393085;">
                                    <img fetchpriority="low" [src]="item.image" [alt]="item.name"
                                        class="img-fluid w-[50px] h-[50px] md:w-[60px] md:h-[60px]"
                                        style="object-fit: contain;margin: auto;">
                                </div>
                            </div>
                            <div class="btn flex justify-center"
                                style="font-family: 'Poppins', sans-serif;font-size:14px;font-weight:400;color:#292929;padding:0px;margin-top:0.6875rem;">
                                {{item.name}}</div>
                            <div *ngIf="item.name=='Door Hangers'"
                                class="text-white bg-[red] absolute w-[37px] h-[37px] text-center flex items-center justify-center"
                                style="object-fit: contain;border-radius:100%;font-size:8px;top:15px;right:0px">New
                            </div>
                        </a>
                    </div>

                    <div class="hidden lg:block" style="float: right;margin-top:70px;">
                        <button style="border:0px;background:none;padding: 0px;margin-top: 7px;">
                            <div (click)="scrollRight('business')"
                                style="margin-left:20px;width:30px;height:30px;padding:6px;margin-right:-20px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                                <img fetchpriority="low" src="assets/images/next_icon.svg"
                                    alt="Business categories scroll right"
                                    style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                            </div>
                        </button>
                    </div>
                </div>
                <!-- / -->
                <!-- Share Your Greetings with loved ones -->
                <p class="title_sm mt-8 mb-8 text-left"
                    style="color:black;font-family: 'Poppins', sans-serif;font-size:23px;font-weight:bold;">
                    Share Your Greetings with loved ones</p>
                <div class="grid grid-cols-2 md:grid-cols-4 gap-3 cursor-pointer">
                    <a class="card" *ngFor="let item of greetings; let i = index"
                        [routerLink]="['/','category', 'greeting-cards', item.id]">
                        <div class="mb-3 aspect-[16/13]"
                            style="border-radius:8px;width:100%;height:auto;object-fit:contain;background-color: #f5f5f5;">
                            <img fetchpriority="low" [src]="item.image" class="h-full w-full object-cover"
                                style="border-radius:8px" [alt]="item.altText">
                        </div>
                        <div class="mb-8" style="font-family: 'Poppins', sans-serif;font-size:14px;font-weight:400;">
                            {{item.name}}</div>
                    </a>
                </div>
                <!-- / -->
                <!-- / -->
                <!-- Redefining Connections in the Digital Age -->
                <p class="text_sm mt-8 mb-8 text-left"
                    style="color:black;font-family: 'Poppins', sans-serif;font-size:23px;font-weight:bold;">Redefining
                    Connections in the
                    Digital Age</p>
                <div class="flex mt-8 mb-8 justify-between">
                    <h2 class="text-2xl text-left"
                        style="color:black;font-family: 'Poppins', sans-serif;font-weight:bold;">
                        Virtual Biz Cards</h2>
                    <div class="flex justify-end gap-3">
                        <div class="flex lg:hidden">
                            <button>
                                <div (click)="scrollLeft('vid')"
                                    style="width:30px;height:30px;padding:6px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                                    <img fetchpriority="low" src="assets/images/prev_icon.svg"
                                        alt="VID cards scroll left"
                                        style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                                </div>
                            </button>
                        </div>
                        <div class="flex lg:hidden">
                            <button>
                                <div (click)="scrollRight('vid')"
                                    style="width:30px;height:30px;padding:6px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                                    <img fetchpriority="low" src="assets/images/next_icon.svg"
                                        alt="VID cards scroll right"
                                        style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flex container w-auto lg:w-[105%] lg:ml-[-30px] items-center"
                    [ngStyle]="{'margin-left.px': difference, 'margin-right.px': difference}"
                    style="padding:0px;margin-bottom:4rem;">
                    <div class="hidden lg:block" style="float: left;">
                        <button style="border:0px;background:none;padding: 0px;margin-top: 7px;">
                            <div (click)="scrollLeft('vid')"
                                style="margin-right:20px;width:30px;height:30px;padding:6px;margin-left:-20px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                                <img fetchpriority="low" src="assets/images/prev_icon.svg" alt="VID cards scroll left"
                                    style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                            </div>
                        </button>
                    </div>

                    <div #cardSCroll class="flex flex-grow-1 overflow-auto gap-3 posters_row lg:pl-[7px] lg:pr-[7px]"
                        style="padding: 0px;scroll-behavior: auto;scrollbar-width: none !important;"
                        [ngStyle]="{'padding-left.px': innerDifference, 'padding-right.px': innerDifference}">
                        <div class="info-widget" style="cursor:pointer;box-shadow:none;"
                            *ngFor="let item of vidCards; let i = index" (click)="openVID(false, item.id)">
                            <div class="flex flex-column w-max " style="background-color:#f5f5f5;border-radius:8px;">
                                <img fetchpriority="low" [src]="item.image" class="img-fluid h-[250px] aspect-[2/3]"
                                    style="object-fit: contain;width:auto;height:100%;margin: auto;"
                                    [alt]="item.altText">
                            </div>
                            <div class="btn flex justify-center posters_title"
                                style="font-family: 'Poppins', sans-serif;font-size:14px;font-weight:400;color:#292929;padding:0px;margin-top:0.6875rem;">
                                {{item.name}}</div>
                        </div>
                    </div>

                    <div class="hidden lg:block" style="float: right;">
                        <button style="border:0px;background:none;padding: 0px;margin-top: 7px;">
                            <div (click)="scrollRight('vid')"
                                style="margin-left:20px;width:30px;height:30px;padding:6px;margin-right:-20px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                                <img fetchpriority="low" src="assets/images/next_icon.svg" alt="VID cards scroll right"
                                    style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                            </div>
                        </button>
                    </div>
                </div>
                <!-- <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-3">
                    <div class="card cursor-pointer" *ngFor="let item of vidCards; let i = index"
                        (click)="openVID(false, item.id)">
                        <div class="h-[150px] sm:h-[270px]"
                            style="background-color:#f5f5f5;border-radius:8px;width:100%;object-fit:cover">
                            <img fetchpriority="low" [src]="item.image"
                                style="width:100%;height:100%;object-fit: contain;border-radius:8px">
                        </div>
                        <p class="mt-3" style="font-family: 'Poppins', sans-serif;font-size:14px;font-weight:400;">
                            {{item.name}}</p>
                    </div>
                </div> -->

                <!-- Email Signatures -->
                <h2 class="text-2xl mt-8 mb-8 text-left"
                    style="color:black;font-family: 'Poppins', sans-serif;font-size:23px;font-weight:bold;">
                    Email Signatures</h2>
                <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-3">
                    <div class="card cursor-pointer" *ngFor="let signature of emailSignatures?.data; let i = index"
                        (click)="openVID(true, signature.id)">
                        <img fetchpriority="low" class="w-full  h-[100px] sm:h-[170px]" [src]="signature.image"
                            style="border-radius:8px;object-fit:contain;background-color: #f5f5f5;"
                            alt="{{signature.name}}">
                        <p class="mt-3" style="font-family: 'Poppins', sans-serif;font-size:14px;font-weight:400;">
                            {{signature.name}}</p>
                    </div>
                </div>
                <!-- / -->
                <!-- Franchise Templates -->
                <div class="flex mt-8 mb-8">
                    <p class="grow title_sm text-left"
                        style="color:black;font-family: 'Poppins', sans-serif;font-size:23px;font-weight:bold;">
                        Franchise Templates</p>
                    <div class="flex justify-end gap-3">
                        <div class="flex lg:hidden">
                            <button>
                                <div (click)="scrollLeft('franchise')"
                                    style="width:30px;height:30px;padding:6px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                                    <img fetchpriority="low" src="assets/images/prev_icon.svg"
                                        alt="Franchise templates scroll left"
                                        style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                                </div>
                            </button>
                        </div>
                        <div class="flex lg:hidden">
                            <button>
                                <div (click)="scrollRight('franchise')"
                                    style="width:30px;height:30px;padding:6px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                                    <img fetchpriority="low" src="assets/images/next_icon.svg"
                                        alt="Franchise templates scroll right"
                                        style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="flex container carousel_item_width lg:w-[105%] lg:ml-[-30px]"
                    style="width:100%;padding:0px;margin-bottom:3rem;">
                    <div class="hidden lg:block" style="float: left;margin-top:50px;">
                        <button style="border:0px;background:none;padding: 0px;margin-top: 7px;">
                            <div (click)="scrollLeft('franchise')"
                                style="margin-right:20px;width:30px;height:30px;padding:6px;margin-left:-20px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                                <img fetchpriority="low" src="assets/images/prev_icon.svg"
                                    alt="Franchise templates scroll left"
                                    style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                            </div>
                        </button>
                    </div>

                    <div #franchiseLogoSCroll class="flex flex-grow-1 overflow-auto"
                        style="padding: 0px;scroll-behavior: auto;scrollbar-width: none !important;">
                        <div class="info-widget" style="box-shadow:none;background-color: #ffffff;"
                            *ngFor="let item of franchiseIcons; let i = index;last as isLast">
                            <div class="flex flex-column franchise_logo_sm w-[90px] h-[90px] sm:w-[210px] sm:h-[130px]"
                                [ngClass]="{'border-r' : !isLast}"
                                style="padding:20px;width: max-content;width:210px;height:130px;border-radius:0px;">
                                <img fetchpriority="low" [src]="item" class="img-fluid" alt="{{item.name}} logo"
                                    style="object-fit: contain;width:100%;height:100%;margin: auto;">
                            </div>
                            <div class="btn flex justify-center"
                                style="font-size: 0.8rem;color:#292929;padding:0px;margin-top:0.6875rem;">{{item.name}}
                            </div>
                        </div>
                    </div>

                    <div class="hidden lg:block" style="float: right;margin-top:50px;">
                        <button style="border:0px;background:none;padding: 0px;margin-top: 7px;">
                            <div (click)="scrollRight('franchise')"
                                style="margin-left:20px;width:30px;height:30px;padding:6px;margin-right:-20px;display:flex;align-items: center;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border:solid 1px #efefff;border-radius:100%;">
                                <img fetchpriority="low" src="assets/images/next_icon.svg"
                                    alt="Franchise templates scroll right"
                                    style="width:100%px;height:100%;cursor:pointer;object-fit: contain;">
                            </div>
                        </button>
                    </div>
                </div>
                <!-- / -->

            </section>
        </div>

    </div>
    <!-- Ebook and get your SEO Section -->
    <div class="grid grid-cols-1 md:grid-cols-2 pt-0 pb-0">
        <div class="flex flex-row justify-end w-full  pl-0 pb-6 pt-6 filter-listing bg-[#f7f4f0]">
            <div class="flex items-center justify-center">
                <img fetchpriority="low" src="./assets/images/ebook_img@3x.png" alt="Printing Warehouses of America eBook cover and sign-up"
                    style="max-width:100%;height:80%;object-fit: cover;object-position: right;">
            </div>
            <div
                class="flex flex-col flex-auto ml-1 sm:ml-6 mr-0 sm:mr-2 mt-8 mb-4 max-w-[240px] sm:max-w-[400px] pr-6">
                <p class="text-left"
                    style="color:#060267;font-family: 'Poppins', sans-serif;font-weight:600;font-size:16px;">
                    Ebook {{date | date:'yyyy'}}</p>
                <p class="text-left ebook_title max-w-[320px] sm:max-w-[300px]"
                    style="color:#060267;font-family: 'Poppins', sans-serif;font-size:26px;font-weight:bold;line-height: 1.2;">
                    Printing Warehouses
                    of America®</p>
                <!-- Email field -->
                <form [formGroup]="ebookForm" #ebookNgForm="ngForm">
                    <div class="mb-6 mt-3">
                        <mat-form-field class="col-span-1 md:col-span-2 w-[200px] sm:w-[270px]"
                            [hideRequiredMarker]="hideRequiredMarker">
                            <mat-label class="text-black" style="font-weight:normal;font-size:12px;">Email
                                Address</mat-label>
                            <input id="email" matInput type="email" [formControlName]="'email'" class="text-black">
                            <mat-error *ngIf="ebookForm.get('email').hasError('required')">
                                Email shouldn't be empty
                            </mat-error>
                            <mat-error *ngIf="ebookForm.get('email').hasError('email')">
                                Please provide a valid email id
                            </mat-error>
                        </mat-form-field>

                        <!-- <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Email
                            Address</label>
                        <input type="email" id="eeBookEail"
                            class="bg-white border-2 border-black-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[200px] sm:w-[270px] p-1.5 dark:bg-gray-700  dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            required> -->
                    </div>
                </form>
                <button class="block -mt-[20px] mb-2 m-left btn_normal w-full sm:w-[200px] " (click)="downloadEbook()"
                    style="align-self: flex-start;">Download</button>
                <p class="mt-2 text-left ebook_text"
                    style="font-size: 11px;font-family: 'Poppins', sans-serif;font-weight:400;">
                    By clicking “Download” you agree to receive marketing emails
                    from Printing Warehouses of America. You also agree that your personal
                    data will be processed in accordance with our Privacy Policy</p>

            </div>
        </div>

        <div class="bg-white pl-4 sm:pl-12 pr-20 pt-16 pb-16 flex items-center"
            style="background-image: url(./assets/images/seo_blogs.webp);background-size: 100% 100%;">
            <div class="max-w-[200px] sm:max-w-[300px] md:max-w-[280px] lg:max-w-[340px]">
                <p class="text-left ebook_title"
                    style="color:#060267;font-family: 'Poppins', sans-serif;font-size:26px;font-weight:bold;max-width:280px;line-height: 1.2;">
                    Get
                    Your SEO
                    Friendly Blogs</p>
                <p class="ext-left mt-2"
                    style="color:#060267;font-weight: 400;font-size:14px;font-family: 'Poppins', sans-serif;">Any
                    topics for $89/blog</p>
                <p class="mt-2 ebook_text text-left"
                    style="font-size: 11px;font-family: 'Poppins', sans-serif;font-weight:400;">
                    Doing this causes more people to connect with you, all you
                    have to do is go in and accept the connections at least once
                    a month, or once a week ideally.

                </p>
                <button class="block w-full sm:w-[200px] mt-6 m-left plan_btn btn_normal" [routerLink]="['/','pricing']"
                    style="padding:0.5rem 1.9rem;line-height: normal;">Choose
                    Your Plan</button>
            </div>
        </div>
    </div>
    <!-- End -->



</div>