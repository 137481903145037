<section class="flex-auto p-0 pl-2 pb-6 pt-0 sm:pt-6 m-auto" style="max-width:90%;">
    <div class="flex flex-col md:flex-row gap-[10px]">
        <div class="flex flex-row relative w-full mt-6 -mr-5">
            <div class="relative w-full">
                <mat-tab-group class="mt-4 sm:mt-0" style="flex-grow: 0;" [(selectedIndex)]="selectedTab"
                    (selectedTabChange)="onTabChanged($event)">
                    <mat-tab label="Shipping">
                        <ng-container *ngIf="showAddressAddButton ; else noAddress">
                            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 mr-5"
                                style="max-height:290px;padding-right:30px;overflow: auto;">
                                <div class="bg-[#f8f7ff] p-6 pl-3 flex items-center w-full gap-3 cursor-pointer"
                                    (click)="selectedShippingAddress = address" style="border-radius:10px;"
                                    *ngFor="let address of addressList$ | async">

                                    <div class="flex radio-bg rounded-full shrink-0 ">
                                        <div class="radio-selected rounded-full shrink-0"
                                            *ngIf="selectedShippingAddress?.id == address.id">
                                        </div>
                                    </div>
                                    <label class="flex flex-col justify-between items-start w-full cursor-pointer">
                                        <div class="text-black mb-0" style="font-weight: 600;font-size:15px;">
                                            {{address.name}}
                                        </div>
                                        <div class="text-black"
                                            style="font-size:13px;font-weight:400;text-wrap: balance; word-break: break-word;">
                                            <span> {{address.address_line_1}},</span>
                                            <span *ngIf="address.address_line_2?.length > 0">
                                                {{address.address_line_2}},
                                                <br></span>
                                            <span> {{address.state}}, </span>
                                            <span> {{address.city}}, </span>
                                            <span> {{address.zipcode}}</span>
                                        </div>
                                    </label>
                                </div>

                            </div>
                        </ng-container>
                        <div class="flex gap-0 items-center mt-7 justify-end px-7 mr-7">
                            <button class="block btn_white" (click)="addAddress()">
                                Add Address
                            </button>
                            <!-- <mat-radio-button class="mb-0 flex justify-between items-center" [value]="'Standard'"
                                id="radio1">
                            </mat-radio-button> -->
                            <!-- <label style="color:#292362;font-weight:500;font-size:15px;">Add New Address</label> -->
                        </div>
                        <div class="mt-12 flex flex-col sm:flex-row justify-end gap-4 items-end sm:items-center">
                            <mat-checkbox [(ngModel)]="isShippingBillingSame"
                                (change)="setSameAddressForBilling($event.checked)" class="example-margin">Use shipping
                                address as billing address</mat-checkbox>
                            <button class="items-center flex justify-center cursor-pointer btn_normal"
                                (click)="showNextTab()" style="font-weight:600 !important;min-width:70px;">
                                Next
                                <!-- <mat-icon svgIcon="heroicons_outline:chevron-right"
                                            style="min-height: 15px; min-width: 15px; height: 15px;width:15px"></mat-icon> -->
                            </button>
                        </div>

                    </mat-tab>
                    <mat-tab label="Billing" [disabled]="selectedShippingAddress == null">
                        <ng-container *ngIf="showAddressAddButton ; else noAddress">
                            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3"
                                style="max-height:290px;overflow: auto;">
                                <div class="bg-[#f8f7ff] p-6 pl-3 flex items-center w-full gap-3 cursor-pointer"
                                    (click)="selectedBillingAddress = address; isShippingBillingSame = selectedBillingAddress.id == selectedShippingAddress.id"
                                    style="border-radius:10px;" *ngFor="let address of addressList$ | async">

                                    <div class="flex radio-bg rounded-full shrink-0 ">
                                        <div class="radio-selected rounded-full shrink-0"
                                            *ngIf="selectedBillingAddress?.id == address.id">
                                        </div>
                                    </div>
                                    <label class="flex flex-col justify-between items-start w-full cursor-pointer">
                                        <div class="text-black mb-0" style="font-weight: 600;font-size:15px;">
                                            {{address.name}}
                                        </div>
                                        <div class="text-black"
                                            style="font-size:13px;font-weight:400;text-wrap: balance; word-break: break-word;">
                                            <span> {{address.address_line_1}},</span>
                                            <span *ngIf="address.address_line_2?.length > 0">
                                                {{address.address_line_2}},
                                                <br></span>
                                            <span> {{address.state}}, </span>
                                            <span> {{address.city}}, </span>
                                            <span> {{address.zipcode}}</span>
                                        </div>
                                    </label>
                                </div>

                            </div>
                        </ng-container>
                        <div class="divider-y mt-8 hidden"></div>
                        <div class="flex gap-0 items-center justify-between mt-12">
                            <button class="block btn_white" (click)="addAddress()">
                                Add Address
                            </button>
                            <button class="items-center flex justify-center cursor-pointer btn_normal"
                                (click)="showNextTab()" style="font-weight:600 !important;min-width:70px;">
                                Next
                                <!-- <mat-icon svgIcon="heroicons_outline:chevron-right"
                                            style="min-height: 15px; min-width: 15px; height: 15px;width:15px"></mat-icon> -->
                            </button>
                            <!-- <mat-radio-button class="mb-0 flex justify-between items-center" [value]="'Standard'"
                                id="radio1">
                            </mat-radio-button>
                            <label style="color:#292362;font-weight:500;font-size:15px;">Add New Address</label> -->
                        </div>
                    </mat-tab>
                    <mat-tab label="Payment" [disabled]="selectedBillingAddress == null">
                        <div class="flex flex-wrap gap-6 justify-between w-full">
                            <div class="grow">

                                <div class="grid grid-cols-1 gap-4 sm:gap-[40px]" style="gap:50px">
                                    <div>
                                        <p class="text-black mb-3" style="font-weight: bold;font-size:15px;">Shipping
                                            Address</p>
                                        <p class="text-black mb-0" style="font-weight: 600;font-size:15px;">
                                            {{selectedShippingAddress?.name}}
                                        </p>
                                        <p class="text-black" style="font-weight: 400;font-size:14px;">
                                            <span> {{selectedShippingAddress?.address_line_1}},</span>
                                            <span *ngIf="selectedShippingAddress?.address_line_2?.length > 0">
                                                {{selectedShippingAddress?.address_line_2}},
                                                <br></span>
                                            <span> {{selectedShippingAddress?.state}}, </span>
                                            <span> {{selectedShippingAddress?.city}}, </span>
                                            <span> {{selectedShippingAddress?.zipcode}}</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p class="text-black mb-3" style="font-weight: bold;font-size:15px;">Billing
                                            Address</p>
                                        <p class="text-black mb-0" style="font-weight: 600;font-size:14px;">
                                            {{selectedBillingAddress?.name}}
                                        </p>
                                        <p class="text-black" style="font-weight: 400;font-size:13px;">
                                            <span> {{selectedBillingAddress?.address_line_1}},</span>
                                            <span *ngIf="selectedBillingAddress?.address_line_2?.length > 0">
                                                {{selectedBillingAddress?.address_line_2}},
                                                <br></span>
                                            <span> {{selectedBillingAddress?.state}}, </span>
                                            <span> {{selectedBillingAddress?.city}}, </span>
                                            <span> {{selectedBillingAddress?.zipcode}}</span>
                                        </p>
                                    </div>
                                </div>

                                <div class="divider-y"></div>
                                <ng-container *ngIf="showCardAddButton ; else noCards">
                                    <h1 class="font-bold text-lg mb-3">Saved Cards</h1>
                                    <div class="flex flex-col gap-6">
                                        <div *ngFor="let card of cards$ | async"
                                            class="flex flex-row gap-6 cursor-pointer items-center"
                                            (click)="selectedCard = card.id">
                                            <div class="flex radio-bg rounded-full shrink-0 ">
                                                <div class="radio-selected rounded-full shrink-0"
                                                    *ngIf="selectedCard == card.id">
                                                </div>
                                            </div>
                                            <div class="flex items-center">
                                                <img [src]="'./assets/images/card/'+card.brand+'.png'"
                                                    onerror="this.src='./assets/images/card/credit_card.png'"
                                                    style="width:40px;height:40px;object-fit: contain;"
                                                    alt="Card image">
                                                <span class="ml-4" style="font-size:12px;">xxxx xxxx xxxx
                                                    {{card.last4}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="divider-y"></div>
                                <div class="flex gap-6 items-center cursor-pointer" *ngIf="showCardAddButton"
                                    (click)="addCard()">
                                    <div class="flex radio-bg rounded-full shrink-0 ">
                                        <div class="radio rounded-full shrink-0">
                                        </div>
                                    </div>
                                    <div class="flex items-center text-[#292362] text-lg font-semibold grow">
                                        Add New Card
                                    </div>
                                    <div class="flex justify-between items-center">
                                        <img src="./assets/images/visa_card.png" class="w-[100px] object-contain"
                                            alt="Visa card">
                                    </div>

                                    <!-- <button class="block btn_white" (click)="addCard()"
                                style="align-self: flex-start;line-height: normal;">
                                Add Card
                            </button> -->
                                    <!-- <mat-radio-button class="mb-0 flex justify-between items-center" [value]="'Standard'"
                                id="radio1">
                            </mat-radio-button>
                            <label style="color:#292362;font-weight:500;font-size:15px;">Add New Address</label> -->
                                </div>
                            </div>
                            <div class="methods p-5 h-fit" style="border-radius:13px;border:2px solid #e4e3ff;"
                                *ngIf="shippingMethods?.length > 0">
                                <p class="font-bold text-lg mb-6">Shipping Methods</p>
                                <div *ngFor="let shipping of shippingMethods; let isLast = last">
                                    <div class="flex flex-row gap-2  items-center"
                                        (click)="selectedShipping = shipping.id;shipping.selected = true; updateShippingMethos()">
                                        <div class="flex radio-bg rounded-full shrink-0 ">
                                            <div class="radio-selected rounded-full shrink-0"
                                                *ngIf="shipping.id == selectedShipping">
                                            </div>
                                        </div>
                                        <label class="flex flex-row justify-between items-center w-full gap-2">
                                            <div class="text-[#292362] text-md">
                                                {{shipping.title}}
                                            </div>
                                            <div class="text-black flex" style="font-size:14px;font-weight:600;">
                                                ${{shipping.cost}}
                                            </div>
                                        </label>
                                    </div>
                                    <div class="divider-y" *ngIf="!isLast"></div>
                                </div>
                            </div>
                        </div>

                    </mat-tab>

                </mat-tab-group>
            </div>
            <!-- <div class="absolute z-0" style="right:20px;top:10px;" *ngIf="selectedTab != 2">
                <button class="-mt-4 items-center mr-2 hidden sm:flex justify-center cursor-pointer btn_normal"
                    (click)="showNextTab()" style="font-weight:600 !important;min-width:50px !important;">
                    Next
                </button>
            </div> -->
            <!-- <button class="-mt-4 items-center flex mr-2 justify-center cursor-pointer sm:hidden absolute z-0 btn_normal"
                (click)="showNextTab()" *ngIf="selectedTab != 2"
                style="right:0px;font-weight:600 !important;min-width:50px !important;">
                Next
            </button> -->
        </div>
        <div style="padding:0px 24px;">
            <div class="flex-col min-w-auto sm:min-w-[400px] p-[30px] sm:p-[36px]"
                style="border: solid 2px #e4e3ff;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border-radius: 30px;">
                <p class="mb-4" style="font-weight:700;font-size:20px;">Order Summary</p>
                <div class="flex justify-between items-center mb-2">
                    <p style="font-size:14px;font-weight:400;">Sub Total :</p>
                    <div class="text-black flex" style="font-size:15px;font-weight:600;">${{cartDetails?.sub_total}}
                    </div>
                </div>
                <div class="flex justify-between mb-2">
                    <p style="font-size:14px;font-weight:400;">Shipping Charge :</p>
                    <div class="text-black flex" style="font-size:15px;font-weight:600;">${{cartDetails?.shipping}}
                    </div>
                </div>
                <div class="flex justify-between mb-2">
                    <p style="font-size:14px;font-weight:400;">Tax ({{cartDetails?.tax_percentage}}%) :</p>
                    <div class="text-black flex" style="font-size:15px;font-weight:600;">${{cartDetails?.tax}}</div>
                </div>
                <div class="flex justify-between mb-2" *ngIf="cartDetails?.discount > 0">
                    <p style="font-size:14px;font-weight:400;">Discount :</p>
                    <div class="text-black flex" style="font-size:15px;font-weight:600;color: #dd0d00 !important;">
                        -${{cartDetails?.discount}}</div>
                </div>
                <div class="divider-y"></div>
                <div class="flex justify-between items-center mb-2">
                    <p style="font-size:14px;font-weight:400;">Total :</p>
                    <div class="text-[#3723dd] flex" style="font-size:20px;font-weight:600;">
                        ${{cartDetails?.total_price}}
                    </div>
                </div>

                <form>
                    <mat-form-field class="w-full mt-4 mb-4">
                        <mat-label>Additional instructions (optional)</mat-label>
                        <textarea [(ngModel)]="additionalInstructions" class="w-full" name="instructions"
                            matInput></textarea>
                        <mat-hint>Describe your printing instructions here and our team will follow the
                            guidances.</mat-hint>
                    </mat-form-field>

                </form>
                <div class="mt-4 sm:mt-2" style="margin-left: -10px;">
                    <mat-checkbox [(ngModel)]="sendProofForApproval">
                        Send me the proof designs for approval
                    </mat-checkbox>
                </div>

                <div class="block mt-4 w-full text-center cursor-pointer btn_red" (click)="confirmAndPay()"
                    *ngIf="selectedTab == 2">
                    Confirm & Pay
                </div>
                <!-- <div class="text-[#292362] font-semibold text-center underline mt-4 justify-center cursor-pointer"
                    (click)="confirmAndPay(true)" *ngIf="selectedTab == 2">
                    Generate Donation Link</div> -->
                <div class="text-center mt-2" *ngIf="selectedTab == 2">
                    OR
                </div>

                <div (click)="confirmAndPay(true)" *ngIf="selectedTab == 2"
                    class="w-auto mt-2 btn_normal cursor-pointer text-center "
                    style="font-weight:500;font-style: normal;">
                    Generate Donation Link</div>

            </div>


            <div class="flex-col mt-4 min-w-auto sm:min-w-[400px] mb-6"
                style="border: solid 2px #e4e3ff;box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);border-radius: 30px;padding:36px;">
                <p class="mb-4" style="font-weight:700;font-size:20px;">Order Items</p>

                <div *ngFor="let item of cartDetails?.items; let last = last">
                    <div class="flex flex-col sm:flex-row" style="gap:26px">
                        <div class="flex flex-col">
                            <div class="h-full"
                                style="background-color:#f5f5f5;border-radius:8px;width:120px;max-height: 180px;overflow: hidden;">
                                <img [src]="item.image" style="width:100%;height:100%;object-fit: contain;"
                                    [alt]="item.title">
                            </div>
                        </div>
                        <div>
                            <h1 class="mb-1" style="font-weight:700;font-size:14px;">{{item.title}}</h1>
                            <p class="mb-2" style="font-size:12px;font-weight:400;">{{item.template_name}}</p>
                            <div class="flex mb-1" *ngFor="let option of item.options">
                                <p style="font-weight:400;font-size:12px;">{{option.type}}</p>
                                <span class="columnSpace">:</span>
                                <p style="font-weight:400;font-size:12px;">{{option.title}}</p>
                            </div>
                            <div class="flex">
                                <p style="font-weight:400;font-size:12px;">Quantity</p>
                                <span class="columnSpace">:</span>
                                <p style="font-weight:400;font-size:12px;">{{item.quantity}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="divider-y" *ngIf="!last"></div>
                </div>



            </div>
        </div>
    </div>
</section>

<ng-template #noAddress>
    <!-- Order history - Message -->
    <p class="text-black text-center sm:text-start mb-1" style="font-size:18px;font-weight:500;font-family:poppins">
        You haven't saved any addresses yet
    </p>
    <p class="text-black text-center sm:text-start" style="font-size:13px;font-weight:normal;font-family:poppins">
        Looks like you haven't added any addresses yet. Add a new address to ensure smooth and timely delivery of your
        orders.
    </p>

    <!-- / -->
</ng-template>
<ng-template #noCards>
    <!-- Order history - Message -->
    <p class="text-black text-center sm:text-start mb-1" style="font-size:18px;font-weight:500;font-family:poppins">
        You haven't saved any cards yet
    </p>
    <p class="text-black text-center sm:text-start" style="font-size:13px;font-weight:normal;font-family:poppins">
        Oops! It seems you haven't saved any cards yet. Add a new card to make payments hassle-free and secure
    </p>
    <div class="flex mt-8 btn_white m-auto sm:ml-0">
        <button class="blockbtn_white" (click)="addCard()" mat-flat-button
            style="align-self: flex-start;line-height: normal;">
            Add Card
        </button>
    </div>
    <!-- / -->
</ng-template>