import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { Router, RouterModule } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { LocalStorageService } from 'app/core/localstorage/local-storage.service';
import { SocialMediaService } from 'app/core/social-media/social-media.service';
import { SocialmediaServices } from 'app/core/social-media/social-media.types';
import { LoginComponent } from 'app/shared/login/signin.component';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { Subject, takeUntil } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { AutofillManagerService } from '../autofill-manager.service';
import { AutofillContact, AutofillGroup } from '../autofill-manager.types';
import { ImageViewerService } from '../../image-viewer/image-viewer.service';
import { AddContactComponent } from '../add-contact/add-contact.component';
import { SearchPopup } from '../../search/search-popup/search-popup.component';


@Component({
  selector: 'group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss'],
  standalone: true,
  imports: [CommonModule, MatRadioModule, RouterModule, MatMenuModule, MatIconModule, MatButtonModule, MatProgressSpinnerModule]
})

export class AutofillGroupDetailsComponent implements OnInit {

  /**
   * Constructor
   */
  constructor(private _autofillManagerService: AutofillManagerService, private _spinner: NgxSpinnerService,
    private _matDialog: MatDialog, private _localStorage: LocalStorageService,
    private _fuseConfirmationService: FuseConfirmationService, private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef, private _imageViewer: ImageViewerService,
    private _toast: ToastrService, private broadcast: BroadcastService) {

  }

  private _unsubscribeAll: Subject<void> = new Subject<void>();
  contacts: AutofillContact[];
  groupDetails: AutofillGroup
  showPrimaryAddButton = false
  hasMoreItems = false
  showLoading = false
  ngOnInit(): void {
    this._autofillManagerService.contacts$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(contacts => {
        this.contacts = contacts
        this.showPrimaryAddButton = this.contacts.length > 0
        this._changeDetectorRef.markForCheck()
      })
    this._autofillManagerService.group$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(group => {
        this.groupDetails = group
        this._changeDetectorRef.markForCheck()
      })
    this._autofillManagerService.hasMoreContact$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((hasMore: boolean) => {
        this.hasMoreItems = hasMore;
        this._changeDetectorRef.markForCheck();
      });

    this.broadcast.subscribe("account_switch_notification", this._unsubscribeAll, data => {
      this._changeDetectorRef.markForCheck()

    })
  }

  loadNextPage() {
    this.showLoading = true
    this._autofillManagerService.getContacts(this.groupDetails.id, true).subscribe({
      complete: () => { this.showLoading = false }
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  addContact() {
    if (!this.groupDetails.autofill_mapping_completed) {
      this._fuseConfirmationService.open({
        title: "Autofill Maping",
        message: `Please complete autofill mapping to add contacts to the group.`,
        actions: {
          confirm: { label: "Ok" },
          cancel: { show: false }
        }
      });
      return
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.groupDetails
    this._matDialog.open(AddContactComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null && value.length > 1) {
          this._toast.success(value ? value : ("Contact created successfully"), "Success");
          this._autofillManagerService.getContacts(this.groupDetails.id,).subscribe()
        }
      });
  }

  deleteContact(contact: AutofillContact) {
    const dialogRef = this._fuseConfirmationService.open({
      title: "Delete Contact",
      message: `Are you sure you want to delete ${contact.name}?`,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirmed')
        this.proceedDeleteContact(contact)
    });
  }

  proceedDeleteContact(contact: AutofillContact) {
    this._spinner.show();
    this._autofillManagerService.deleteContact(this.groupDetails.id, contact.id).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this._toast.success(res.message ? res.message : 'Contact has been removed successfully', "Success")
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }


  resetContact(contact: AutofillContact) {
    const dialogRef = this._fuseConfirmationService.open({
      title: "Reset Contact",
      message: `Are you sure you want to reset all details of  ${contact.email}?`,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirmed')
        this.proceedResetContact(contact)
    });
  }

  proceedResetContact(contact: AutofillContact) {
    this._spinner.show();
    this._autofillManagerService.updateContact(this.groupDetails.id, contact.id, { "is_completed": false }).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        contact.is_completed = false
        this._toast.success(res.message ? res.message : 'Contact has been reset', "Success")
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  openImage(url) {
    this._imageViewer.open(url)
  }


  proceedOrder() {
    this._spinner?.show()
    this._autofillManagerService.proceedToCart(this.groupDetails.id).subscribe({
      next: response => {
        this._spinner?.hide()
        this.groupDetails.is_completed = true
        this.groupDetails.added_to_cart = true
        let dialog = this._fuseConfirmationService.open({
          title: "Success",
          message: response?.message ?? `The order hasbeen successfuly to cart. Please checkout from the cart.`,
          actions: {
            cancel: { show: false, label: "CANCEL" },
            confirm: { label: "Ok" }
          },
          icon: { show: false }
        })
        dialog.afterClosed().subscribe(result => {
          this._router.navigate(['autofill-manager/', this.groupDetails?.id])
          // if (result.isConfirmed) {
          this._router.navigate(['cart'])
          // } else {
          //   this._router.navigate(['autofill-manager'])
          // }
        })
      },
      error: error => {
        this._spinner?.hide()
        this._toast.error(error ? error.error.message : "Something went wrong", "Sorry")
      }
    })
  }

  chooseDesign() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'calc(100%)'
    // dialogConfig.width = this.isScreenSmall ? 'calc(100%)' : 'calc(80%)'
    dialogConfig.maxWidth = '100%'
    dialogConfig.position = { right: '0px', top: '0px' }
    dialogConfig.panelClass = ['w-full', 'sm:w-4/5', 'animate__animated', 'animate__slideInRight', 'sidebar-dialog']
    this._matDialog.open(SearchPopup, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null) {
          console.log(value);
          this.updateGroupWithVersionHistory(value)
        }
      });
  }

  updateGroupWithVersionHistory(data: any) {

    this._spinner.show()

    let requestData = {
      name: this.groupDetails?.name,
      description: this.groupDetails?.description,
      version_history: data?.history_id,
      quantity_id: data?.quantity,
      options: data?.options
    }

    let request = this._autofillManagerService.updateGroup(this.groupDetails?.id, requestData)
    request.subscribe({
      next: (response: any) => {
        this._spinner.hide()
        this._autofillManagerService.getGroupDetails(this.groupDetails?.id, false).subscribe()
      },
      error: error => {
        this._spinner.hide()
        this._toast.error(error ? error.error.message : "Something went wrong", "Sorry")
      }
    })
  }


}