import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { Router, RouterModule } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { BroadcastService } from 'app/core/broadcast/broadcast.service';
import { CartService } from 'app/core/cart/cart.service';
import { CartDetails, CartItem, Promocode } from 'app/core/cart/cart.types';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { ReviewProductComponent } from './review-product/review-product.component';
import { GlobalService } from 'app/core/auth/global.service';
import { EDITOR_URL } from 'environments/environment';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { PromocodesComponent } from './promocodes/promocodes.component';


@Component({
  selector: 'cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, MatRadioModule, MatSelectModule, RouterModule, MatButtonModule, CommonModule],
})

export class CartComponent implements OnInit {

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  cartDetails: CartDetails = null
  cartItems: CartItem[] = []
  ngOnInit(): void {
    this._cartService.cartDetail$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe(cartDetails => {
        cartDetails.items.forEach(item => {
          item.selectedQuantity = item.quantity_options.find(qty => qty.quantity == item.quantity).id
        })
        this.cartDetails = cartDetails
        this.cartItems = cartDetails.items
        this.promocode = cartDetails.promo_code
        this._changeDetectorRef.markForCheck()
      })
    this.broadcast.subscribe("account_switch_notification", this._unsubscribeAll, data => {
      this._changeDetectorRef.markForCheck()
      this._cartService.getCartDetails().subscribe()
    })
  }

  constructor(private _router: Router,
    private broadcast: BroadcastService, private _toast: ToastrService, private _spinner: NgxSpinnerService,
    private _changeDetectorRef: ChangeDetectorRef, private _cartService: CartService, private _matDialog: MatDialog,
    private _fuseConfirmationService: FuseConfirmationService, private globalService: GlobalService,) {

  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  deleteCartItem(cartItem: CartItem) {
    const dialogRef = this._fuseConfirmationService.open({
      title: "Remove from Cart",
      message: `Are you sure you want to remove the item?`,

    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'confirmed')
        this.proceedDeleteCartItem(cartItem)
    });
  }

  proceedDeleteCartItem(cartItem: CartItem) {
    this._spinner.show();
    this._cartService.deleteCartItem(cartItem.id).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this._cartService.getCartDetails().subscribe()
        this._toast.success(res.message ? res.message : 'Item has been removed successfully', "Success")
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  onQuantityChange(item: CartItem, value) {
    let data = {
      "quantity": item.quantity_options.find(qty => qty.id == item.selectedQuantity).id,
      "options": item.options.map(a => a.id)
    }
    this.updateCartItem(item, data)
  }

  updateCartItem(item, data) {
    this._spinner.show();
    this._cartService.updateCartItem(item.id, data).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this._cartService.getCartDetails().subscribe()
        this._toast.success(res.message ? res.message : 'Quantity has been updated successfully', "Success")
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  getDetailsForReview(cartItem: CartItem) {
    this._spinner.show();
    this._cartService.getCartItemDetails(cartItem.id).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this.openReviewPopup(res)
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  openReviewPopup(cartItem: CartItem) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = cartItem
    this._matDialog.open(ReviewProductComponent, dialogConfig)
      .afterClosed().subscribe(value => {
        if (value != null) {
          if (value.modifyDesign) {
            this.openEditor(value.designId)
          } else {
            this._toast.success(value ?? ("Product review submitted successfully"), "Success");
            this._cartService.getCartDetails().subscribe()
          }
        }
      });
  }

  openEditor(designId: number) {
    this.globalService.setCookie()
    window.open(`${EDITOR_URL}/?id=` + designId, '_blank');
  }

  promocode = ""
  promocodeError = ""
  applyPromocode() {

    if (this.promocode.length < 3) {
      this.promocodeError = "Please provide a valid promocode"
      return
    }

    this.promocodeError = null
    this._spinner.show()
    this._cartService.applyPromoCode(this.promocode).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this.cartDetails.promo_code = res.promo_code;
        this.cartDetails.total_price = res.total_price;
        this.cartDetails.sub_total = res.sub_total;
        this.cartDetails.discount = res.discount;
        this.cartDetails.promo_code_info = res.promo_code_info
        this._toast.success("Promocode applied successfully", "Success")
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  removePromocode() {
    this._spinner.show()
    this._cartService.removePromoCode().subscribe({
      next: (res: any) => {
        this._spinner.hide();
        this.cartDetails.promo_code = res.promo_code;
        this.cartDetails.total_price = res.total_price;
        this.cartDetails.sub_total = res.sub_total;
        this.cartDetails.discount = res.discount;
        this.promocode = ''
        this._toast.success("Promocode removed successfully", "Success")
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  getPromocodes() {
    this._spinner.show()
    this._cartService.getPromocodes().subscribe({
      next: (res: Promocode[]) => {
        this._spinner.hide();
        if (res.length == 0)
          this._toast.error("There are no promocodes available now", "Sorry")
        else
          this.showPromocodesModal(res)
      },
      error: (err) => {
        this._spinner.hide();
        this._toast.error(err ? err.error.message : "Something went wrong. Please try again!", "Sorry")
      }
    })
  }

  showPromocodesModal(promocodes: Promocode[]) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { codes: promocodes, isFromCart: true }
    this._matDialog.open(PromocodesComponent, dialogConfig)
      .afterClosed().subscribe((value: any) => {
        if (value != null) {
          if (value.promocode) {
            this.promocode = value.promocode.code
            this.applyPromocode()
          }
        }
      });
  }

  checkout() {
    let incompleteItem = this.cartItems.find(item => !item.review_completed)
    if (incompleteItem != null) {
      this._toast.error("Please review all item(s) before checkout", "Sorry")
      return
    }
    this._router.navigate(['/', 'checkout'])
  }

}

