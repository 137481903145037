<mat-dialog-actions class="flex justify-end pl-4 mb-0 p-0" style="min-height:fit-content !important">
    <!-- Title -->
    <!-- <div class="flex justify-between pl-4 "> -->
    <div class="flex justify-between items-center w-full">
        <h1 class="text-black text-left" style="font-weight:700;font-size:20px;">New Post</h1>
        <button class="flex w-auto justify-end items-center mb-0" mat-button (click)="close()">
            <img alt="Close icon" src="./assets/images/close_icon.svg"
                style="width:17px;height:17px;object-fit: contain;">
        </button>
    </div>
</mat-dialog-actions>
<mat-dialog-content class="p-0 w-full max-w-full max-h-[80vh] overflow-visible">

    <div class="w-full">
        <!-- Content starts -->
        <div class="flex flex-col sm:flex-row gap-6 mt-4 w-full">
            <div class="grow">
                <div class="gap-2"
                    [ngClass]="{'sm:flex' : (!selectedVersions || selectedVersions?.length == 0)  && fileToUpload == null}">
                    <div class="flex gap-3 mb-2 sm:mb-0 items-center bg-[#f8f7ff] p-4 cursor-pointer"
                        style="border-radius:10px;" (click)="selectProfileImage.click();"
                        *ngIf="!selectedVersions || selectedVersions?.length == 0">
                        <img src="./assets/images/upload_btn.svg" style="width:24px;height:24px;" alt="Upload button">
                        <div>
                            <h1 class="mb-1" style="color:#060267;font-weight:700;font-size:11px;line-height: normal;">
                                Upload Your Design
                            </h1>
                            <p class="text-black" style="font-size: 10px;line-height: normal;">
                                Upload design from your device</p>
                        </div>
                    </div>
                    <div class="flex gap-3 items-center bg-[#f8f7ff] p-4 cursor-pointer" style="border-radius:10px;"
                        *ngIf="fileToUpload == null" (click)="openLibrary()">
                        <img src="./assets/images/library_btn.svg" style="width:24px;height:24px;" alt="Choose from library">
                        <div>
                            <h1 class="mb-1" style="color:#060267;font-weight:700;font-size:11px;line-height: normal;">
                                Choose from Library</h1>
                            <p class="text-black" style="font-size: 10px;line-height: normal;">
                                Upload design from your library</p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col w-full mt-4">
                    <mat-form-field class="w-full mb-2" subscriptSizing="dynamic">
                        <mat-label class="text-black">Post To</mat-label>
                        <mat-select class="border-none" placeholder="Choose social media accounts"
                            [(ngModel)]="selectedAccounts" [ngModelOptions]="{standalone: true}" multiple>
                            <mat-optgroup *ngIf="smmAccounts?.facebook?.length > 0" [label]="'Facebook'">
                                <ng-container *ngFor="let facebook of smmAccounts?.facebook">
                                    <mat-option [value]="'facebook_profile'+facebook.id" class="border-b border-t">
                                        <div class="flex flex-row items-center gap-2">
                                            <img [src]="facebook.image_url" class="w-5 h-5 rounded-full"
                                                onError="this.src = 'assets/images/profile-icon.webp'"
                                                alt="Profile image">
                                            <div>
                                                {{facebook.name}}
                                            </div>
                                        </div>
                                    </mat-option>
                                    <mat-option *ngFor="let page of facebook?.pages" [value]="'facebook_page'+page.id">
                                        <div class="flex flex-row items-center gap-2">
                                            <img [src]="page.image_url" class="w-5 h-5 rounded-full"
                                                onError="this.src = 'assets/images/profile-icon.webp'"
                                                alt="Profile image">
                                            <div>
                                                {{page.title}}
                                            </div>
                                        </div>
                                    </mat-option>
                                </ng-container>
                            </mat-optgroup>

                            <mat-optgroup *ngIf="smmAccounts?.linkedin?.length > 0" [label]="'Linkedin'">
                                <ng-container *ngFor="let linkedin of smmAccounts?.linkedin">
                                    <mat-option [value]="'linkedin_profile'+linkedin.linkedin_id">
                                        <div class="flex flex-row items-center gap-2">
                                            <img [src]="linkedin.profile_image_url" class="w-5 h-5 rounded-full"
                                                onError="this.src = 'assets/images/profile-icon.webp'"
                                                alt="Profile image">
                                            <div>
                                                {{linkedin.name}}
                                            </div>
                                        </div>
                                    </mat-option>
                                </ng-container>
                            </mat-optgroup>

                            <mat-optgroup *ngIf="smmAccounts?.twitter?.length > 0" [label]="'Twitter'">
                                <ng-container *ngFor="let twitter of smmAccounts?.twitter">
                                    <mat-option [value]="'twitter_profile'+twitter.twitter_id">
                                        <div class="flex flex-row items-center gap-2">
                                            <img [src]="twitter.profile_image_url" class="w-5 h-5 rounded-full"
                                                onError="this.src = 'assets/images/profile-icon.webp'"
                                                alt="Profile image">
                                            <div>
                                                {{twitter.screen_name}}
                                            </div>
                                        </div>
                                    </mat-option>
                                </ng-container>
                            </mat-optgroup>

                            <mat-optgroup *ngIf="smmAccounts?.instagram?.length > 0" [label]="'Instagram'">
                                <ng-container *ngFor="let instagram of smmAccounts?.instagram">
                                    <mat-option [value]="'instagram_profile'+instagram.id">
                                        <div class="flex flex-row items-center gap-2">
                                            <img [src]="instagram.image_url" class="w-5 h-5 rounded-full"
                                                onError="this.src = 'assets/images/profile-icon.webp'"
                                                alt="Profile image">
                                            <div>
                                                {{instagram.page_title}}
                                            </div>
                                        </div>
                                    </mat-option>
                                    <mat-option *ngFor="let page of instagram?.pages"
                                        [value]="'instagram_page'+page.id">
                                        <div class="flex flex-row items-center gap-2">
                                            <img [src]="page.image_url" class="w-5 h-5 rounded-full"
                                                onError="this.src = 'assets/images/profile-icon.webp'"
                                                alt="Profile image">
                                            <div>
                                                {{page.title}}
                                            </div>
                                        </div>
                                    </mat-option>
                                </ng-container>
                            </mat-optgroup>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full" floatLabel="never" subscriptSizing="dynamic">
                        <mat-label class=" text-black">Text</mat-label>
                        <textarea rows="5" matInput [(ngModel)]="postContent" [ngModelOptions]="{standalone: true}"
                            class="w-full" #message
                            style="border-color:#343434 !important;border-radius: 12px; border: none !important;"></textarea>
                        <mat-hint align="start"><strong>X has 280 character limit</strong> </mat-hint>
                        <mat-hint align="end">{{message.value.length}} / 280</mat-hint>
                    </mat-form-field>
                    <mat-checkbox class="-ml-[10px] mt-2" [(ngModel)]="schedulePost"
                        [ngModelOptions]="{standalone: true}">
                        Schedule this post
                    </mat-checkbox>

                    <label class="mt-2 text-black font-medium" *ngIf="schedulePost">Schedule Time</label>
                    <input [min]="date" class="w-full text-black" type="datetime-local" *ngIf="schedulePost"
                        [ngModelOptions]="{standalone: true}" [(ngModel)]="scheduledTime" autocomplete="false" />

                    <mat-form-field class="w-full mt-2" subscriptSizing="dynamic" *ngIf="schedulePost">
                        <mat-label>Repeat Post</mat-label>
                        <mat-select placeholder="Repeat post" [(ngModel)]="postFrequency"
                            [ngModelOptions]="{standalone: true}">
                            <mat-option [value]="''">Do not repeat</mat-option>
                            <mat-option [value]="'daily'">Daily</mat-option>
                            <mat-option [value]="'monthly'">Monthly</mat-option>
                            <mat-option [value]="'yearly'">Yearly</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="flex max-h-full w-full sm:w-[320px] flex-col shrink-0">
                <div class="flex justify-center h-full"
                    style="background-color:#f5f5f5;border-radius:20px;padding:30px;">
                    <div *ngIf="postContent?.length == 0 && versionImages?.length == 0" class="m-auto">
                        <img src="assets/images/post_image_thumbnail.svg" class="w-20 h-20" alt="Thumbnail">
                    </div>
                    <fuse-card class="flex flex-col w-full filter-post p-4"
                        *ngIf="postContent?.length > 0 || versionImages?.length > 0">
                        <!-- <div class="flex items-center mx-4 mt-6 mb-4">
                        <img class="w-10 h-10 rounded-full mr-4" src="assets/images/avatars/female-14.jpg"
                            alt="Card cover image">
                        <div class="flex flex-col">
                            <span class="font-semibold leading-none">Marleah Eagleston</span>
                            <span class="text-xs text-secondary leading-none mt-1">Published on 23 Mar 2023
                                11:49 AM</span>
                        </div>
                    </div> -->
                        <div class="flex flex-col min-h-26">
                            <ng-container *ngIf="versionImages?.length > 0">
                                <!-- <img [src]="imageRes" class="w-full h-full min-h-26 object-contain"> -->
                                <pwa-image-carousel class="w-full shrink-0 h-60 bg-white" [bgColor]="white"
                                    [images]="versionImages"
                                    style="border-radius:8px;overflow: hidden;"></pwa-image-carousel>
                            </ng-container>
                            <p *ngIf="postContent?.length > 0" class="w-full"
                                style="overflow-wrap: break-word;white-space: pre-wrap;">
                                {{postContent}}
                            </p>

                        </div>
                    </fuse-card>
                </div>
                <!-- Content ends -->
                <div class="flex shrink-0 gap-2 flex-col sm:flex-row justify-end mt-6 w-full items-center pb-4">
                    <button mat-flat-button class="btn_white w-full sm:w-auto" (click)="submitPost(true)"
                        *ngIf="!schedulePost">
                        <div class="flex items-center justify-center">
                            <span class="mr-2">
                                <img src="./assets/images/schedule_icon.svg" style="height:20px;width:auto" alt="Schedule icon">
                            </span>Save as Draft
                        </div>
                    </button>
                    <button mat-flat-button class="mt-0 btn_normal w-full sm:w-auto" (click)="submitPost(false)"
                        style="padding:0.6rem 1.2rem !important;">
                        <div class="flex items-center justify-center sm:justify-end">
                            <span class="mr-2"><img src="./assets/images/post_btn.svg" style="width:auto;height:20px;" alt="Post now">
                            </span>
                            {{schedulePost ? 'Schedule Now' : 'Post Now'}}
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>

</mat-dialog-content>

<input accept="image/*" onclick="this.value = null" (change)="onImageChanged($event)" #selectProfileImage hidden="true"
    type="file" class="form-control text-black">

<!-- <mat-dialog-actions class="pb-4 pr-0 pt-2 pl-2 justify-end mt-4"> -->
<!-- Submit button -->
<!-- <button class="fuse-mat-button-large w-50 mt-3" mat-flat-button [color]="'primary'" type="submit"
        [disabled]="createChecklistForm.disabled" (click)="submitForm()" form="createChecklistForm">
        <span *ngIf="!createChecklistForm.disabled">
            SUBMIT
        </span>
        <mat-progress-spinner *ngIf="createChecklistForm.disabled" [diameter]="24" [mode]="'indeterminate'">
        </mat-progress-spinner>
    </button> -->

<!-- </mat-dialog-actions> -->